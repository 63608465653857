import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import { Container, Grid, Icon, MenuItem, Typography, Loader, Badge, Button, Modal, ModalBody, Tabs, Line, Box, ModalHeader } from "@cbmisorg/client-app";
import { routeMngRequestedJoinCtrl } from "./controler/routeMngRequestedJoin.controller";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import NoResultImg from "../../../sharedUi/noResultImg/NoResultImg";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import * as appVariables from "../../../../appHelper/appVariables";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import * as appColor from "../../../../appHelper/appColor";

function RouteMngRequestedJoin() {
  useCheckCat();

  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState.clientInfo.strLanguage;
  const labels = dictionary?.components?.mnageRequestJoin;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    bigCurrentTab: appVariables.objUserStatus.Applicant,
    lstUser: [],

    blnOpenSortModal: false,

    blnOpenUserDetailModal: false,
    objUserDetail: {},

    intNumViewItem: 0,
  });

  const fldSort = FormData({
    intGPA: { strControl: "list", lstOption: [] },
    bigDegreeID: { strControl: "list", lstOption: [] },
    bigMajorID: { strControl: "list", lstOption: [] },
    bigEmploymentID: { strControl: "list", lstOption: [] },
    bigCountryOfCitizenID: { strControl: "list", lstOption: [] },
    bigCountryOfResidenceID: { strControl: "list", lstOption: [] },
    strUniversityName: { strControl: "list", lstOption: [] },
    bigAcademicYearID: { strControl: "list", lstOption: [] },
    lstUserRole: { strControl: "list", lstOption: [] },
    strFullNameSearch: { strControl: "text", objLanguage: false },
  });

  const fields = FormData({
    strUserEmail: { strControl: "text", objLanguage: false },
    strUserPhone: { strControl: "text", objLanguage: false },
    strFullName: { strControl: "text", objLanguage: false },
    dtmDob: { strControl: "date", lstOption: [] },
    strGovID: { strControl: "text", objLanguage: false },
    bigMajorID: { strControl: "list", lstOption: [] },
    strAddress: { strControl: "text", objLanguage: false },
    bigDegreeID: { strControl: "list", lstOption: [] },
    intGPA: { strControl: "list", lstOption: [] },
    bigGender: { strControl: "list", lstOption: [] },
    dtmGradDate: { strControl: "date", lstOption: [] },
    bigEmploymentID: { strControl: "list", lstOption: [] },
    bigCountryOfCitizenID: { strControl: "list", lstOption: [] },
    bigCountryOfResidenceID: { strControl: "list", lstOption: [] },
    strUniversityName: { strControl: "list", lstOption: [] },
    bigAcademicYearID: { strControl: "list", lstOption: [] },
    strWorkPlace: { strControl: "text", objLanguage: false },
    strPositionTitle: { strControl: "text", objLanguage: false },
  });

  const handlers = routeMngRequestedJoinCtrl({ appState, appDispatch });
  const initData = handlers.initData({ isLoading, setIsLoading, state: stateInfo, setState: setStateInfo, fldSort, fields });
  const onChangeCountry = handlers.onChangeCountry({ fldSort });
  const resetFilterFields = handlers.resetFilterFields({ fldSort, state: stateInfo, setState: setStateInfo });
  const filterUser = handlers.filterUser({ fldSort, state: stateInfo });
  const openUserDetail = handlers.openUserDetail({ fields, state: stateInfo, setState: setStateInfo });
  const onChangeCountryForUser = handlers.onChangeCountryForUser({ fields });
  const updateUserInDB = handlers.updateUserInDB({ isLoading, setIsLoading, state: stateInfo, setState: setStateInfo, fields });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-3>
        {isLoading ? <Loader backdrop={true} color={appColor.App_Primary_Color} /> : null}

        <Grid container justify={"center"}>
          {!stateInfo?.lstUser?.length ? (
            <NoResultImg />
          ) : (
            <>
              <Grid item xs={12} p-0 container justify={"space-between"}>
                <Grid item xs={9} sm="11" p-0 pt-2 sx={{ ".cbmis-tabs": { width: "100% !important", maxWidth: "99% !important" } }}>
                  <Tabs
                    mode="box"
                    activeColor="primary"
                    p-0
                    dir={lang === "arb" ? "rtl" : "ltr"}
                    currentTab={1}
                    tabsContent={[
                      { tabLabel: labels?.pendingUser?.[lang], onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Applicant }) },
                      { tabLabel: labels?.approvedUser?.[lang], onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Approved }) },
                      { tabLabel: labels?.excludedUser?.[lang], onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Rejected }) },
                    ]}
                  />
                </Grid>
                <Grid item px-2>
                  <Button icon="filterAlt" size="sm" p-1 onClick={() => setStateInfo({ ...stateInfo, blnOpenSortModal: true })} />
                </Grid>
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={6}>
                  <FormField objHandler={fldSort.lstUserRole} dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
                <Grid item xs={6} sx={{ background: "#ffffff" }} p-0>
                  <FormField objHandler={fldSort?.strFullNameSearch} endIcon={"search"} dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
              </Grid>

              {stateInfo?.lstUser?.map((item, index) => {
                if (index === 0) {
                  stateInfo.intNumViewItem = 0;
                }
                if (!filterUser(item)) {
                  if (!stateInfo.intNumViewItem && index >= stateInfo?.lstUser?.length - 1) {
                    return <NoResultImg key={index} xs={6} />;
                  }
                  return null;
                }
                ++stateInfo.intNumViewItem;

                return (
                  <Grid item xs="12" key={item?.bigUserID}>
                    <MenuItem
                      sx={{
                        width: "100%",
                        background:
                          String(item?.bigUserStatusID) === String(appVariables.objUserStatus.Applicant)
                            ? appColor.App_Light_blue
                            : String(item?.bigUserStatusID) === String(appVariables.objUserStatus.Approved)
                            ? appColor.App_Light_green
                            : appColor.App_Light_Gray,
                        color:
                          String(item?.bigUserStatusID) === String(appVariables.objUserStatus.Applicant)
                            ? appColor.App_Primary_Color
                            : String(item?.bigUserStatusID) === String(appVariables.objUserStatus.Approved)
                            ? appColor.App_Dark_green
                            : appColor.App_Gray_Color,
                      }}
                      headItem={
                        item?.jsnUserInfo?.blnIsSeen === true ? (
                          <Icon icon={"AccountBox"} pt-1 color="current-color" />
                        ) : (
                          <Badge mode="dot" sx={{ background: "#FFC700 !important" }}>
                            <Icon icon={"AccountBox"} pt-1 color={"primary"} />
                          </Badge>
                        )
                      }
                      outlined
                    >
                      <Grid container justify={"space-between"}>
                        <Grid item xs="8" pb-0>
                          <Typography as="caption" pb-0 color="current-color" className="traineeName">
                            {
                              typeof item?.jsnUserInfo?.strFullName === "object" ? item?.jsnUserInfo?.strFullName?.[lang] : item?.jsnUserInfo?.strFullName
                            }
                          </Typography>
                        </Grid>
                        <Grid item px-0>
                          <Grid item>
                            <Box
                              sx={{
                                borderRadius: "1em",
                                background: item?.blnIsManager ? appColor.App_Primary_Color : item?.blnIsStartUp ? appColor.App_Dark_Bluewood : appColor.App_Dark_blue,
                                color: "#ffffff",
                                fontSize: "8px",
                                border: `1px solid ${appColor.App_Beige_Color1}`,
                                padding: "0px 4px",
                              }}
                            >
                              {item?.blnIsManager ? labels?.manager?.[lang] : item?.blnIsStartUp ? labels?.entrepreneur?.[lang] : labels?.student?.[lang]}
                            </Box>
                          </Grid>
                          <Grid item>
                            <Button
                              icon="visibility"
                              p-0
                              onClick={openUserDetail(item)}
                              color={
                                item?.jsnUserInfo?.blnIsSeen && String(item?.bigUserStatusID) === String(appVariables.objUserStatus.Applicant)
                                  ? appColor.App_Primary_Color
                                  : String(item?.bigUserStatusID) === String(appVariables.objUserStatus.Approved)
                                  ? appColor.App_Dark_green
                                  : appColor.App_Gray_Color
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      </Container>

      <Modal open={stateInfo.blnOpenSortModal} eventClose={() => setStateInfo({ ...stateInfo, blnOpenSortModal: false })}>
        <ModalBody>
          <Grid container>
            <Grid item xs={12}>
              <Button icon="restartAlt" size="sm" p-0 onClick={resetFilterFields} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormField objHandler={fldSort.bigCountryOfCitizenID} icon="flag" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField objHandler={fldSort.bigCountryOfResidenceID} icon="LocationOn" onChange={onChangeCountry} dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.strUniversityName} icon="locationCity" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigMajorID} icon="localLibrary" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigEmploymentID} icon="work" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigDegreeID} icon="historyEdu" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigAcademicYearID} icon="school" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.intGPA} icon="insertChartOutlined" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>

          <Grid container justify="center" mt-4>
            <Grid item xs={12}>
              <Button icon="filterList" size="sm" label={labels?.filterBtn?.[lang]} p-1 onClick={() => setStateInfo({ ...stateInfo, blnOpenSortModal: false })} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={stateInfo.blnOpenUserDetailModal} eventClose={() => setStateInfo({ ...stateInfo, blnOpenUserDetailModal: false })} size="xl">
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Grid container justify={"space-between"}>
            {String(stateInfo.objUserDetail?.bigUserStatusID) === String(appVariables.objUserStatus.Applicant) || !stateInfo?.objUserDetail?.bigUserStatusID ? (
              <Grid item xs="12" sm="8">
                <Typography as="caption">{labels.note?.[lang]}</Typography>
              </Grid>
            ) : String(stateInfo.objUserDetail?.bigUserStatusID) === String(appVariables.objUserStatus.Approved) ? (
              <Grid item xs="12" sm="8">
                <Typography as="caption">{labels.noteApproved?.[lang]}</Typography>
              </Grid>
            ) : (
              <Grid item xs="12" sm="8">
                <Typography as="caption">{labels.notExclude?.[lang]}</Typography>
              </Grid>
            )}
            <Grid item container xs="12" sm="auto">
              {String(stateInfo?.objUserDetail?.bigUserStatusID) === String(appVariables?.objUserStatus?.Approved) ? (
                <Grid item />
              ) : (
                <Grid item>
                  <Button
                    label={
                      <Typography as="caption" p-0>
                        {labels?.approve?.[lang]}
                      </Typography>
                    }
                    color="success"
                    size="sm"
                    py-0
                    sx={{ width: "55px !important" }}
                    onClick={updateUserInDB(appVariables.objUserStatus.Approved)}
                  />
                </Grid>
              )}
              {String(stateInfo?.objUserDetail?.bigUserStatusID) === String(appVariables?.objUserStatus?.Rejected) ? (
                <Grid item />
              ) : (
                <Grid item>
                  <Button
                    label={
                      <Typography as="caption" p-0>
                        {String(stateInfo?.objUserDetail?.bigUserStatusID) === String(appVariables?.objUserStatus?.Approved) ? labels?.exception?.[lang] : labels?.exclude?.[lang]}
                      </Typography>
                    }
                    color="error"
                    size="sm"
                    py-0
                    sx={{ width: "55px !important" }}
                    onClick={updateUserInDB(appVariables.objUserStatus.Rejected)}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Line my-3 />
          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fields.strFullName} icon="person" />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fields.strUserEmail} icon="email" readOnly={true} dir={"ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fields.strUserPhone} icon="phone" readOnly={true} dir={"ltr"} />
            </Grid>
            {!fields.bigCountryOfCitizenID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" />
              </Grid>
            )}
            {!fields.bigCountryOfResidenceID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigCountryOfResidenceID} icon="LocationOn" onChange={onChangeCountryForUser} />
              </Grid>
            )}
            {!fields.strUniversityName?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strUniversityName} icon="business" />
              </Grid>
            )}
            {!fields.dtmDob?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.dtmDob} icon="cake" />
              </Grid>
            )}
            {!fields.strGovID?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strGovID} icon="creditCard" />
              </Grid>
            )}
            {!fields.bigMajorID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigMajorID} icon="localLibrary" />
              </Grid>
            )}
            {!fields.strAddress?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strAddress} icon="directionsCar" />
              </Grid>
            )}
            {!fields.bigDegreeID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigDegreeID} icon="historyEdu" />
              </Grid>
            )}
            {!fields.bigGender?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigGender} icon="wc" />
              </Grid>
            )}
            {!fields.dtmGradDate?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.dtmGradDate} icon="school" />
              </Grid>
            )}
            {!fields.bigEmploymentID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigEmploymentID} icon="work" />
              </Grid>
            )}

            {!fields.bigAcademicYearID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigAcademicYearID} icon="today" />
              </Grid>
            )}
            {!fields.intGPA?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.intGPA} icon="barChart" />
              </Grid>
            )}
            {!fields.strWorkPlace?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strWorkPlace} icon="accountBalance" />
              </Grid>
            )}
            {!fields.strPositionTitle?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strPositionTitle} icon="work" />
              </Grid>
            )}
          </Grid>

          {String(stateInfo.objUserDetail?.bigUserStatusID) === String(appVariables.objUserStatus.Applicant) || !stateInfo?.objUserDetail?.bigUserStatusID ? null : (
            <React.Fragment>
              <Line my-3 />
              <Grid container justify={"center"}>
                <Grid item>
                  <Button label={labels?.update?.[lang]} onClick={updateUserInDB(null)} />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default RouteMngRequestedJoin;
