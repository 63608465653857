import { Container, Grid, Icon, Typography } from '@cbmisorg/client-app'
import React from 'react'
import { App_Blue_Color, App_Green_Color, App_Green_Color2, App_Orange_Color, App_Parable_Color, App_Red_Color } from '../../../../../../appHelper/appColor'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary';

const lstIcon = ["ShoppingCart","Public","PrecisionManufacturing","LocalAtm","Attractions","School"];
const lstColor = [App_Parable_Color,App_Orange_Color,App_Green_Color,App_Blue_Color,App_Red_Color,App_Green_Color2]

function Support({lang}) {
    const labels = dictionary?.components?.public?.home?.support

    return (
        <React.Fragment>
            <Container py-10 id="support">
                <Grid container spacing={0}  justify={'center'}>
                    <Grid item xs="12">
                        <Typography as="h4" >
                            {labels?.title?.[lang]}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={5} justify={'center'} py-5>
                {
                    labels?.lst?.map((item,index)=>{
                        return(
                            <Grid item xs="12" md="6" lg="4" container key={index} justify={'center'} alignSelf={'flex-start'} spacing={0}>
                                <Grid item xs="12">
                                    <Icon icon={lstIcon?.[index]} color={lstColor?.[index]}/>
                                </Grid>
                                <Grid item xs="12">
                                    <Typography as="h6">
                                        {item?.heading?.[lang]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="12">
                                    <Typography as="body1">
                                        {item?.describe?.[lang]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    })
                }
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default Support