import React, { useContext, useState } from "react";
import { AppContext } from "../../../configuration/contextapi/AppContext";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
import { Button, Container, Grid, Icon, Loader, Paper, Typography, useNavigate } from "@cbmisorg/client-app";
import ShredHeader from "../../sharedUi/header/ShredHeader";
import { App_Primary_Color } from "../../../appHelper/appColor";
import { FormData, FormField } from "../../../appHelper/formApi/FormAPI";
import { CtrlForgetPassword } from "./controller/CtrlForgetPassword";

const classes = {
  formContainer: {
    height: "80vh",
  },
  image: {
    width: "100%",
    margin: "auto",
    transform: "translateY(30%)",
  },
  button: {
    width: "100%",
  },
};
const labels = dictionary.components?.public?.ForgetPassword;
function ForgetPassword() {
  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    bigUserID:0,
    getEmail: "",
    strVerificationCode: "",
    blnIsSendVerifyCode: false,
    blnIsVerificationCode: false,
  });

  const fieldsEmail = FormData({
    strUserEmail: {
      strControl: "text",
      objValidations: { required: true, email: true },
      objLanguage: false,
    },
  });
  const fieldsCode = FormData({
    strVerificationCode: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
  });
  const fieldsPassword = FormData({
    strUserPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 4  } },
    },
    strConfirmPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 4  }, equal: { condition: "strUserPassword" } },
    },
  });

  const handlers = CtrlForgetPassword(appState, appDispatch);
  const getEmail = handlers.getEmail({ isLoading, setIsLoading, state, setState, fields: fieldsEmail });
  const getCode = handlers.getCode({ state, setState, fields: fieldsCode });
  const funRestPassword = handlers.funRestPassword({ isLoading, setIsLoading, state, setState, fields: fieldsPassword, navigate });

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} />
      <Grid container spacing={0} p-0 m-0 justify="center" m-auto>
        <Grid container item xs="12" md="8" lg="9" xl="10" justify="center" p-0>
          <Grid item xs="12" md="8" lg="7" xl="4 " sx={classes.formContainer} px-2 pt-0>
            <Container m-auto>
              <Paper className="cardContainer">
                {isLoading ? <Loader backdrop={true} color={App_Primary_Color} /> : null}
                <Grid container justify="center">
                  <Grid item xs="12">
                    <Icon icon="vpnKey" color="primary" className="loginIcon" />
                  </Grid>
                  <Grid item xs="12" pb-3>
                    <Typography as="h4" color={"primary"}>
                      {labels?.title?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item py-0>
                    <Typography as="body2" sx={classes.heading} pt-0>
                      {dictionary.components.public.login.dontHaveAccount?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container justify="center" spacing={2} hidden={state?.getEmail}>
                  <Grid item xs="12">
                    <Typography as="subtitle2" color={App_Primary_Color}>
                      {labels?.subTitle1?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item md={"12"} xs={"12"} py-2>
                    <FormField objHandler={fieldsEmail.strUserEmail} icon="email" className={`inputLeft inputLeft${lang}`} iconColor={App_Primary_Color} />
                  </Grid>
                  <Grid item xs="12">
                    <Button label={<Typography as="subtitle1">{labels?.btnSubmit?.[lang]}</Typography>} py-0 size="sm" sx={classes.button} onClick={getEmail} />
                  </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} hidden={!state?.blnIsSendVerifyCode || state?.blnIsVerificationCode}>
                  <Grid item xs="12">
                    <Typography as="subtitle2" color={App_Primary_Color}>
                      {labels?.subTitle2?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item md={"12"} xs={"12"} py-2>
                    <FormField objHandler={fieldsCode.strVerificationCode} icon="password" className={`inputLeft inputLeft${lang}`} iconColor={App_Primary_Color} />
                  </Grid>
                  <Grid item xs="12">
                    <Button label={<Typography as="subtitle1">{labels?.btnSubmit?.[lang]}</Typography>} py-0 size="sm" sx={classes.button} onClick={getCode} />
                  </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} hidden={!state.blnIsVerificationCode}>
                  <Grid item xs="12">
                    <Typography as="subtitle2" color={App_Primary_Color}>
                      {labels?.subTitle3?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormField objHandler={fieldsPassword.strUserPassword} icon="vpnKey" className={`inputLeft`} dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField objHandler={fieldsPassword.strConfirmPassword} icon="vpnKey" className={`inputLeft`} dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                  <Grid item xs="12">
                    <Button label={<Typography as="subtitle1">{labels?.btnSubmit?.[lang]}</Typography>} py-0 size="sm" sx={classes.button} onClick={funRestPassword}/>
                  </Grid>
                </Grid>
              </Paper>
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ForgetPassword;
