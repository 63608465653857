import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, Typography, Nav, useNavigate, Button, Loader, Paper, Icon } from "@cbmisorg/client-app";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
import { AppContext } from "../../../configuration/contextapi/AppContext";
import * as appColor from "../../../appHelper/appColor";
import ShredHeader from "../../sharedUi/header/ShredHeader";
import objAppRouting from "../../../appHelper/routing/appRouting";
import { FormData, FormField } from "../../../appHelper/formApi/FormAPI";
import websiteBackground from "../../../assets/images/websiteBackground.png"

import { controller } from "./controller/CtrlLogin";
import useCheckSystemInfo from "../../../appHelper/appHook/useCheckSystemInfo";

const classes = {
  paperImage: {
    height: "90vh",
    background: `url(${websiteBackground})`,
  },
  formContainer: {
    height: "80vh",
  },
  image: {
    width: "100%",
    margin: "auto",
    transform: "translateY(30%)",
  },
  button: {
    width: "100%",
  },
};

const labels = dictionary.components?.public?.login;
export default function RouteLogin() {
  useCheckSystemInfo();
  const { appState, appDispatch } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const lang = appState?.clientInfo?.strLanguage;

  const fields = FormData({
    strUserEmail: {
      strControl: "text",
      objValidations: { required: true, email: true },
      objLanguage: false,
    },
    strUserPassword: {
      strControl: "password",
      objLanguage: false,
      objValidations: { required: true, minLength: { condition: 4 } },
    },
    blnRememberMe: {
      strControl: "checkbox",
      objLanguage: false,
      lstOption: [{ key: "blnRememberMe", value: dictionary.formapi?.blnRememberMe?.options?.blnRememberMe?.[lang] }],
    },
  });

  useEffect(() => {
    if(appState.clientInfo?.blnIsUserLoggedIn) {
      navigate(objAppRouting?.Account?.url)
    }
  }, [])

  const navigate = useNavigate();
  const control = controller(appState, appDispatch, isLoading, setIsLoading);

  return (
    <React.Fragment>
      {!isLoading ? null : <Loader color={appColor?.App_Primary_Color} backdrop={true} />}

      <ShredHeader lang={lang} />
      <Grid container spacing={0} p-0 m-0 justify="center">
        {
          // <Grid item xs="0" md="4" lg="3" xl="2">
          // <Paper elevation="4" sx={classes.paperImage}>
          //   <Grid container justify="center" alignSelf="center" spacing={5}>
          //     <Grid item xs="2" md="12">
          //       <img src={loginImg} alt="Welcome Image" style={classes?.image} />
          //     </Grid>
          //   </Grid>
          // </Paper>
          // </Grid>
        }
        <Grid container item xs="12" md="8" lg="9" xl="10" justify="center" p-0>
          <Grid item xs="12" md="8" lg="7" xl="6" sx={classes.formContainer} px-2 pt-0>
            <Container m-auto>
              <Paper className="cardContainer">
                {isLoading ? <Loader backdrop={true} color={appColor.App_Primary_Color} /> : null}
                <Grid container justify="center">
                  <Grid item xs="12">
                    <Icon icon="lock" color="primary" className="loginIcon" />
                  </Grid>
                  <Grid item xs="12" pb-3>
                    <Typography as="h4" color={"primary"}>
                      {labels?.heading?.[lang]}
                    </Typography>
                  </Grid>
                  <Grid item py-0>
                    <Typography as="body2" sx={classes.heading} pt-0>
                      {dictionary.components.public.login.dontHaveAccount?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Grid container justify="center" py-3>
                    <Grid item md={"12"} xs={"12"} py-2>
                      <FormField objHandler={fields.strUserEmail} icon="email" className={`inputLeft inputLeft${lang}`} iconColor={appColor.App_Primary_Color} />
                    </Grid>

                    <Grid item md={"12"} xs={"12"} py-2>
                      <FormField objHandler={fields.strUserPassword} icon="vpnKey" iconColor={appColor.App_Primary_Color} className="inputLeft" dir={lang === "arb" ? "rtl" : "ltr"} />
                    </Grid>

                    <Grid item xs={"12"} py-2>
                      <Button
                        onClick={control.submitLogin({ fields: fields, navigate: navigate })}
                        onKeyDownCapture={control.submitLogin({ fields: fields, navigate: navigate })}
                        label={<Typography as="subtitle1">{labels?.btnLogin?.[lang]}</Typography>}
                        py-0
                        sx={classes.button}
                      />
                    </Grid>
                    <Grid item xs="12" container justify="space-between" spacing="0" py-0>
                      <Grid item px-1 pt-0>
                        {
                          <Typography as="caption" color={appColor?.App_Primary_Color} pt-0 mt-0>
                            <Nav  pt-0 mt-0 onClick={()=>navigate(objAppRouting?.Join_Trainee?.url, {state:{secID:"#itc"}})}>
                              <i>{labels?.reg?.[lang]}</i>
                            </Nav>
                          </Typography>
                        }
                      </Grid>
                      <Grid item>
                        {
                          <Typography as="caption" color={appColor?.App_Primary_Color} pt-0 mt-0>
                            <Nav path={objAppRouting?.Public_RestPassword?.url}>
                              <i>{labels?.forgetPassword?.[lang]}</i>
                            </Nav>
                          </Typography>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <input type="submit" style={{ visibility: "hidden" }} onClick={control.submitLogin({ fields: fields, navigate: navigate })} />
                </form>
              </Paper>
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
