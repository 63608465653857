import React, { useContext } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import AdminAccount from "../../admin/account/Account";
import AccountEntrepreneur from "../../Entrepreneur/accountEntrepreneur/AccountEntrepreneur";
import AccountSponsor from "../../Sponsor/accountSponsor/AccountSponsor";
import { objUserRole } from "../../../../appHelper/appVariables";
import { clearAppLocalStorage } from "../../../../appHelper/appFunctions";
import { ClientDirection, Confirmation, useNavigate } from "@cbmisorg/client-app";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import ShredHeader from "../../../sharedUi/header/ShredHeader";

function Account() {
  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState?.clientInfo?.strLanguage;
  const navigate = useNavigate();

  const funLogout = () => {
    Confirmation.viewConfirmation(
      dictionary?.shared?.confirmationMsg?.logout?.[lang],
      () => {
        clearAppLocalStorage(appState);
        navigate(objAppRouting?.Public_LandingPage?.url, { replace: true });
      },
      () => {},
      dictionary?.shared?.confirmationMsg?.yesSure?.[lang],
      dictionary?.shared?.confirmationMsg?.no?.[lang]
    );
  };

  const handelChangeLang = () => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
    appState.clientInfo.strLanguage = lang === "arb" ? "eng" : "arb";
    appState.clientInfo.strDataLanguage = lang === "arb" ? "eng" : "arb";
    appDispatch();
  };
  return (
    <React.Fragment>
      <ShredHeader
        title={dictionary?.components?.public?.footer?.account?.[lang]}
        lang={lang}
        blnHome={false}
        blnLogout={true}
        funLogout={funLogout}
        blnLang={true}
        changLang={handelChangeLang}
      />

      {appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.Student ? <AccountEntrepreneur /> : <AdminAccount />}
    </React.Fragment>
  );
}

export default Account;
