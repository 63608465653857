import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../configuration/contextapi/AppContext';
import { ClientFunction, Container, Grid, Icon, MenuItem, Paper, Typography,Loader, useNavigate, Badge, Checkbox, } from '@cbmisorg/client-app';
import { App_Primary_Color } from '../../../../appHelper/appColor';
import { CtrlContact } from './controler/CtrlContact';
import ShredHeader from '../../../sharedUi/header/ShredHeader';
import { dictionary } from '../../../../appHelper/appLanguage/dictionary';
import NoResultImg from '../../../sharedUi/noResultImg/NoResultImg';
import objAppRouting from '../../../../appHelper/routing/appRouting';

const classes={
  heading:{
    background:App_Primary_Color,
    color:"#fff"
  }
}
function RouteContact() {
  const { appState } = useContext(AppContext);
  const lang = appState.clientInfo.strLanguage
  const labels = dictionary?.components?.contact
   const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [stateInfo, setStateInfo] = useState({
      objCatInfo:{},
      blnIsInitDone:false,
      lstMail:[],
      lstMailChecked:[],
      blnIsDeleted:false,
      openModal:false,
      selected:null
  })

  const handlers = CtrlContact(appState,isLoading, setIsLoading,stateInfo, setStateInfo);

  useEffect(() => {
    if(!stateInfo?.blnIsInitDone){
      handlers?.getMail()
    }
  }, [])


  return (
    <React.Fragment>
      <ShredHeader title={labels?.mnmTitle?.[lang]} lang={lang} blnBottomBar={false}/>
      <Container className="emailView" py-3>
      {
        isLoading?<Loader backdrop={true} color={App_Primary_Color}/>:null
      }
       <Grid container justify={'center'}>
          <Grid item xs="12">
            <Paper sx={{minHeight:"85vh",maxHeight:"85vh",overflowY:"auto"}} p-0>
              <Grid container sx={classes?.heading} justify={'space-between'}>
              <Grid item>
                {
                  !stateInfo.lstMail?.length?null:(
                    <Checkbox 
                      options={[{key:"0",value:""}]} 
                      onChange={handlers?.checkAll}
                      checked={stateInfo.lstMailChecked?.length}
                      checkMode={stateInfo?.lstMailChecked?.length === stateInfo?.lstMail?.length?"tick":"indeterminate"}

                    />
                  )
                }
              </Grid>

                <Grid item>
                  <Typography as="body1">{labels?.inbox?.[lang]}</Typography>
                </Grid>

                <Grid item>
                {
                  !stateInfo.lstMail?.length?null:(
                      <Icon icon={stateInfo?.blnIsDeleted?"MenuOpen":"delete"}  onClick={handlers?.delateAllMailConfirmation} disabled={!stateInfo?.lstMailChecked?.length}/>
                  )
                }
                </Grid>
              </Grid>
              {
                !stateInfo?.lstMail?.length?(
                  <Grid container justify={'center'}>
                    <Grid item xs="12">
                      <NoResultImg type='message' />
                    </Grid>
                  </Grid>
                ):(
                  <Grid container>
                    {
                      stateInfo?.lstMail?.map(item=>{
                        return(
                          <Grid item xs="12" container key={item?.bigMailID}>
                            <Grid item sx={{width:"3%",sm:{width:"8%"}}} p-0>
                               <Checkbox options={[{key:item?.bigMailID,value:""}]} 
                                        checked={stateInfo.lstMailChecked?.includes(item?.bigMailID)}
                                        onChange={handlers?.checkMail}
                                        />
                            </Grid>
                            <Grid item sx={{width:"97%", sm:{width:"92%"}}} p-0>
                              <MenuItem sx={{width:"100%"}} 
                               headItem={item?.blnIsSeen?<Icon icon={item?.blnIsSeen? "Drafts" : "email"} pt-1 color="#aaa"/>:<Badge mode='dot' sx={{background:"green !important"}}><Icon icon={item?.blnIsSeen? "Drafts" : "email"} pt-1 color={"#FFC900"}/></Badge>}
                                outlined
                                onClick={()=>navigate(objAppRouting?.viewEmail?.url,{state:{mailSelected:item}}, { replace: true })}
                               >
                                <Grid container justify={'space-between'}>
                                  <Grid item container spacing={0}>
                                    <Grid item xs="12" pb-0>
                                       <Typography as="subtitle1" pb-0>{item?.jsnMailInfo?.strFullName}</Typography>
                                    </Grid>
                                    <Grid item xs="12" pt-0>
                                      <Typography as="body2" pt-0>{item?.jsnMailInfo?.strSubject}</Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid item px-0>
                                    <Typography as="body2" sx={{textAlign:"end"}}>{ClientFunction?.getDateUSFormat(item?.dtmCreatedDate)}</Typography>
                                  </Grid>
                                </Grid>
                              </MenuItem>
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                )
               }
            </Paper>
         </Grid>
       </Grid>
      </Container>

    </React.Fragment>
  )
}

export default RouteContact