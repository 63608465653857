import React from 'react'
import {Container, Grid, Line, Typography } from "@cbmisorg/client-app";
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary';

  function Vision({ lang }) {
    const labels = dictionary?.components?.public?.home?.vision
    return (
      <React.Fragment>
        <Container py-5 id="vision">
                <Grid container spacing={0}  justify={'center'}>
                    <Grid item xs="12">
                        <Typography as="h4" >
                            {labels?.title?.[lang]}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={5} justify={'center'} py-10>
                  <Grid item xs="12" md="4" alignSelf={"flex-start"}>
                    <img src={require("../../../../../../assets/landing-images/ourmission1.png")} alt="ourmission" height="445px" width= "290px"/>
                  </Grid>
                  <Grid item xs="12" md="0" sx={{sm:{display:"block  !important"}}}>
                    <Line />
                  </Grid>
                  <Grid item xs="12" md="4" alignSelf={"flex-start"}>
                    <img src={require("../../../../../../assets/landing-images/ourvision2.png")} alt="ourvision" height="475px" width= "290px"/>
                  </Grid>
                  <Grid item xs="12" md="0" sx={{sm:{display:"block  !important"}}}>
                    <Line />
                  </Grid>
                  <Grid item xs="12" md="4" alignSelf={"flex-start"}>
                    <img src={require("../../../../../../assets/landing-images/ourteam1.png")} alt="ourteam" height="445px" width= "290px"/>
                  </Grid>
                </Grid>
        </Container>
      </React.Fragment>
    );
  }

export default Vision