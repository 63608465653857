import { fetchData } from "../appData/appFetch";
import { generateQueriesType } from "./GenerateQueryType.d.ts";

const objTableName = {
  "tblCategory": "tblCategory",
  "tblContact": "tblContact",
  "tblError": "tblError",
  "tblStatistic": "tblStatistic",
  "tblSystem": "tblSystem",
  "tblUser": "tblUser",
  "tblSubscription": "tblSubscription",
};

export const objTableAttributes = {
  "tblCategory": { full: ["id", "bigID", "strName", "jsnValue", "bigParentID", "jsnParentSeries", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblContact": { full: ["id", "bigContactID", "bigSystemID", "bigUserID", "blnForSystem", "strContactEmail", "jsnContactInfo", "blnIsDeleted", "blnIsSeen", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblError": { full: ["id", "strAppType", "strPath", "jsnDetail", "jsnUserInfo", "blnIsSolved", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblStatistic": { full: ["id", "bigStatisticID", "strStatisticType", "bigSystemID", "jsnStatisticInfo", "intCountMail", "intCountSubscription", "intCountJoinEntrepreneur", "intCountJoinManager", "intCountJoinStudent", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblSystem": { full: ["id", "bigSystemID", "strSystemUrlCode", "jsnSystemName", "jsnLstAnnouncement", "jsnLstNotification", "jsnNavigation", "jsnSocialMedia", "jsnPermissions", "jsnLanguage", "jsnContact", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblUser": { full: ["id", "bigUserID", "bigUserRoleID", "bigUserStatusID", "bigSystemID", "strUserPassword", "strUserEmail", "strUserPhone", "strUsername", "jsnUserInfo", "txtLoginKeys", "txtLoginIP", "intNumLoginAttempt", "blnIsAccountLocked", "dtmAccountLocked", "strLastLoginUserAgent", "strLastLoginIPAddress", "dtmLastLogin", "txtChangePasswordInfo", "txtLoginHistoryInfo", "jsnResumeInfo", "jsnPortfolio", "blnIsManager", "blnIsStudent", "blnIsStartUp", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
  "tblSubscription": { full: ["id", "bigSystemID", "bigSubscriptionID", "strSubscriptionEmail", "blnIsDeleted", "blnIsActive", "dtmCreatedDate", "dtmUpdatedDate"] },
};

const objResolverName = {
  tblCategory: {
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    itcAppIncDecInt: { itcAppIncDecInt: "mca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblContact: {
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    itcAppIncDecInt: { itcAppIncDecInt: "mca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblError: {
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    itcAppIncDecInt: { itcAppIncDecInt: "mca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblStatistic: {
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    itcAppIncDecInt: { itcAppIncDecInt: "mca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblSystem: {
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    itcAppIncDecInt: { itcAppIncDecInt: "mca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblUser: {
    itcAppUserAdd: { itcAppUserAdd: "mi" },
    itcAppUserUpdate: { itcAppUserUpdate: "mci" },
    itcAppUserResetPassword: { itcAppUserResetPassword: "mci" },
    itcAppUserAddCustom: { itcAppUserAddCustom: "mi" },
    itcAppUpdateSpecialUserFeature: { itcAppUpdateSpecialUserFeature: "mci" },
    itcAppUpdateLoginUserConfig: { itcAppUpdateLoginUserConfig: "mci" },
    itcAppUserFindAll: { itcAppUserFindAll: "qca" },
    itcAppUserFindOne: { itcAppUserFindOne: "qca" },
    itcAppUserLogin: { itcAppUserLogin: "qc" },
    itcAppFindLoginUserConfig: { itcAppFindLoginUserConfig: "qc" },
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    itcAppIncDecInt: { itcAppIncDecInt: "mca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
  tblSubscription: {
    itcAppAdd: { itcAppAdd: "mi" },
    itcAppUpdate: { itcAppUpdate: "mci" },
    itcAppServerConfigUpdate: { itcAppServerConfigUpdate: "mci" },
    itcAppIncDecInt: { itcAppIncDecInt: "mca" },
    itcAppFindOne: { itcAppFindOne: "qca" },
    itcAppFindAll: { itcAppFindAll: "qca" },
    itcAppServerConfigFindOne: { itcAppServerConfigFindOne: "qca" },
    itcAppServerConfigFindOneGraphqlSchemaAndTables: { itcAppServerConfigFindOneGraphqlSchemaAndTables: "qca" },
  },
};

 /* 
 q | m
 i input
 c condition
 a attribute
 */

/**
 *
 * @type {generateQueriesType}
 */
export function generateQueries(tableName, appInputName = "appInput") {
  return async function returnQuery(strPageInfo, appState, queryName, objInput) {
    const [strQueryName, operation] = Object.entries(objResolverName?.[objTableName[tableName]]?.[queryName])?.[0];
    const queryBody = (strTableName, objectInput, objectCondition, arrAttributes) => `  
  ${String(operation).includes("m") ? "mutation" : "query"}{
    ${strQueryName}(
      ${appInputName}:{
      strTableName: """${strTableName}""",
      ${String(operation).includes("i") ? `strObjectInput: """${objectInput}""",` : ""}
      ${String(operation).includes("c") ? `strObjectCondition: """${objectCondition}""",` : ""}
      ${String(operation).includes("a") ? `strArrayOfStrAttributes: """${arrAttributes}""",` : ""}
     }){
      blnIsResponseEncrypted
      blnIsRequestSuccessful
      strResponseType
      serverResponse
    }
  }`;
   

    return await fetchData(strPageInfo, {
      appName: "itcApp",
      tableName: objTableName?.[tableName],
      objectInput: objInput?.objectInput,
      objectCondition: objInput?.objectCondition,
      arrAttributes: objInput?.arrAttributes,
      queryName: queryName,
      queryBody: queryBody,
      appState: appState,
    });
  };
}