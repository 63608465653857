import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useForm, FormField as ViewFormField, ClientFunction } from "@cbmisorg/client-app";
import { FormControlTypeInput, FormDataType, FormReturn } from "./Form.d.ts";
import * as appFunctions from "../appFunctions";
import { AppContext } from "../../configuration/contextapi/AppContext";
import { appLanguage, dictionary } from "../../appHelper/appLanguage/dictionary";
import { App_Primary_Color } from "../appColor.js";

/**
 * @type {FormDataType}
 */
export function FormData({
  strName,
  value,
  strControl,
  strLabel,
  strPlaceholder,
  lstOption,
  objOptionLang,
  objValidations = {},
  objHidden = {},
  objValidationsMsg = null,
  objLanguage = null,
  ...rest
}) {
  const objRef = useRef({ lstFormControl: [] });
  const { appState } = useContext(AppContext);

  const getFields = useMemo(() => {
    try {
      let objFields = {};
      let objLabels = {};
      let objValidationMsg = {};

      if (rest && Object.entries(rest)?.length > 0) {
        Object.entries(rest).forEach(([fieldName, value]) => {
          objFields[fieldName] = { ...objFields[fieldName] };
          //fields
          objFields[fieldName].control = value?.strControl;
          objFields[fieldName].value = value?.value
            ? value?.value
            : String(value?.strControl).toLocaleLowerCase() === "checkbox" || String(value?.strControl).toLocaleLowerCase() === "multiplelist"
              ? []
              : "";
          objFields[fieldName].error = "";
          objFields[fieldName].validations = value?.objValidations || {};
          objFields[fieldName].hidden = objHidden || {};
          objFields[fieldName].objLanguage = {
            isMultiLanguage: value?.objLanguage === false ? false : true,
            language: value?.objLanguage === false ? { eng: appLanguage.eng } : !value?.objLanguage ? null : value?.objLanguage,
          };
          objFields[fieldName].options = value?.lstOption ? value?.lstOption : [];

          ///////////////////////////////////////////////////////////////////////////////

          objLabels[fieldName] = { ...objLabels[fieldName] };
          objLabels[fieldName].label = value?.strLabel ? value?.strLabel : dictionary?.formapi?.[fieldName]?.label || "";
          objLabels[fieldName].placeholder = value?.strPlaceholder ? value?.strPlaceholder : dictionary?.formapi?.[fieldName]?.placeholder || "";
          objLabels[fieldName].validationsMsg = {};
          objLabels[fieldName].options = value?.objOptionLang || {};
          ///////////////////////////////////////////////////////////////////////////////

          if (!value?.objValidationsMsg) {
            Object.entries(value?.objValidations || {}).forEach(([validationName, value1]) => {
              if (dictionary?.formapi?.[fieldName]?.validationsMsg?.[validationName]) {
                objLabels[fieldName].validationsMsg[validationName] = dictionary?.formapi?.[fieldName]?.validationsMsg?.[validationName];
              }
            });
          } else {
            objLabels[fieldName].validationsMsg = { ...objLabels[fieldName].validationsMsg, ...value?.objValidationsMsg };
          }

          ///////////////////////////////////////////////////////////////////////////////
        });
      }

      if (!objValidationsMsg && strName) {
        Object.entries(objValidations).forEach(([key, value]) => {
          if (dictionary?.formapi?.[strName]?.validationsMsg?.[key]) {
            objValidationMsg[key] = dictionary?.formapi?.[strName]?.validationsMsg?.[key];
          }
        });
      }

      return { fields: objFields, labels: objLabels, validationsMsg: objValidationMsg };
    } catch {
      return { fields: {}, labels: {}, validationsMsg: {} };
    }
  }, []);

  const searchParams = new URL(window.location.href);
  const extLang = searchParams.searchParams.get("lang") || null;
  const initialState = useRef({
    appLanguage:extLang || appState?.clientInfo?.strLanguage || "eng",
    appDefaultLanguage: "eng",
    objLanguage: appLanguage,

    labels: {
      ...(strName
        ? {
          [strName]: {
            label: strLabel ? strLabel : dictionary?.formapi?.[strName]?.label || "",
            placeholder: strPlaceholder ? strPlaceholder : dictionary?.formapi?.[strName]?.placeholder || "",
            options: objOptionLang || {},
            validationsMsg: { ...getFields.validationsMsg },
          },
        }
        : {}),
      ...getFields.labels,
    },

    validationsMsg: {
      ...dictionary.shared.formValidationsMsg,
    },

    fields: {
      ...(!strName
        ? {}
        : {
          [strName]: {
            control: strControl,
            value: value ? value : String(strControl).toLocaleLowerCase() === "checkbox" || String(strControl).toLocaleLowerCase() === "multiplelist" ? [] : "",
            error: "",
            validations: objValidations || {},
            hidden: objHidden || {},
            objLanguage: {
              isMultiLanguage: objLanguage === false ? false : true,
              language: objLanguage === false ? { eng: appLanguage.eng } : !objLanguage ? null : objLanguage,
            },
            options: lstOption ? lstOption : [],
          },
        }),
      ...getFields.fields,
    },
  });

  const { controller } = useForm(initialState.current);

  useEffect(() => {
    if (String(controller?.formState?.appLanguage) !== String(appState?.clientInfo?.strLanguage)) {
      controller?.setFormState({ ...controller?.formState, appLanguage: appState?.clientInfo?.strLanguage });
    }
  }, [strName, value, objValidations, appState?.clientInfo?.strLanguage]);

  function getObjHandler() {
    try {
      let objFields = {};
      if (rest && Object.entries(rest)?.length > 0) {
        Object.entries(rest).forEach(([fieldName, value]) => {
          objFields[fieldName] = { ...objFields[fieldName] };

          objFields[fieldName] = {
            controller: controller,
            strName: fieldName,

            getValue: () => getValueErrorWrapper(fieldName),
            setOptions: (lstOptionExt, objOptionLang = {}) => {
              controller.setOptions(fieldName, lstOptionExt, objOptionLang);
            },
            setValue: (fieldValue) => {
              controller.setValue(
                fieldName,
                fieldValue
                  ? fieldValue
                  : String(value?.strControl).toLocaleLowerCase() === "checkbox" || String(value?.strControl).toLocaleLowerCase() === "multiplelist"
                    ? []
                    : ""
              );
              /*  setValueManually(
                fieldValue,
                fieldName,
                value.strControl,
                controller?.formState?.fields?.[fieldName]?.options,
                controller?.formState?.fields?.[fieldName]?.objLanguage?.isMultiLanguage
              ); */
            },
          };
        });
      }

      return objFields;
    } catch {
      return {};
    }
  }

  const getValueErrorWrapper = (strFieldName) => {
    try {
      if (strFieldName) {
        return controller.getValueByFieldName(strFieldName);
      } else {
        if (String(strControl).toLocaleLowerCase() === "checkbox" || String(strControl).toLocaleLowerCase() === "multiplelist") {
          return [];
        }

        return "";
      }
    } catch {
      if (String(strControl).toLocaleLowerCase() === "checkbox" || String(strControl).toLocaleLowerCase() === "multiplelist") {
        return [];
      }

      return "";
    }
  };

  const setValueManually = (value, strFieldName, strControl, lstOption, isMultiLanguage) => {
    let lstOptionTmp = Array.isArray(lstOption) ? lstOption : [];
    if ((strControl === "list" || strControl === "radio" || strControl === "switch") && typeof value !== "object") {
      let objValue = {};

      for (let i = 0; i < lstOptionTmp.length; i++) {
        if (String(lstOptionTmp[i]?.key) === String(value)) {
          objValue = lstOptionTmp[i];
          break;
        }
      }

      controller.setValue(strFieldName, objValue);
    } else if (strControl === "checkbox" || strControl === "multiplelist") {
      let valueTmp = Array.isArray(value) ? value : [];

      let isPrimitiveValues = valueTmp.every((item) => typeof item !== "object");

      if (isPrimitiveValues) {
        let letValue = [];
        for (let i = 0; i < lstOptionTmp.length; i++) {
          if (valueTmp.includes(String(lstOptionTmp[i]?.key)) || valueTmp.includes(Number(lstOptionTmp[i]?.key))) {
            letValue.push(lstOptionTmp[i]);
          }
        }

        controller.setValue(strFieldName, letValue);
      } else {
        controller.setValue(strFieldName, value);
      }
    } else if ((strControl === "text" || strControl === "textarea") && isMultiLanguage && typeof value === "object") {
      controller.setValue(strFieldName, JSON.parse(JSON.stringify(value)));
    } else {
      controller.setValue(strFieldName, value || "");
    }
  };

  const buildFormListExternal = (lstSetNewField, blnIsAppend = false) => {
    try {
      if (!lstSetNewField || !Array.isArray(lstSetNewField) || lstSetNewField?.length < 1) {
        return;
      }

      let lstOfficialField = lstSetNewField;

      let lstFormControlTmp = [];
      let lstFieldForm = [];

      for (let i = 0; i < lstOfficialField.length; i++) {
        let validationsMsg = {};
        if (!lstOfficialField?.[i]?.objValidationsMsg) {
          Object.entries(lstOfficialField?.[i]?.objValidations || {}).forEach(([validationName, value1]) => {
            if (dictionary?.formapi?.[lstOfficialField?.[i]?.strName]?.validationsMsg?.[validationName]) {
              validationsMsg[validationName] = dictionary?.formapi?.[lstOfficialField?.[i]?.strName]?.validationsMsg?.[validationName];
            }
          });
        } else {
          validationsMsg = { ...validationsMsg, ...lstOfficialField?.[i]?.objValidationsMsg };
        }

        lstFieldForm.push({
          fieldName: lstOfficialField?.[i]?.strName,

          fields: {
            control: lstOfficialField?.[i]?.strControl,
            value: lstOfficialField?.[i]?.value
              ? lstOfficialField?.[i]?.value
              : String(lstOfficialField?.[i]?.strControl).toLocaleLowerCase() === "checkbox" ||
                String(lstOfficialField?.[i]?.strControl).toLocaleLowerCase() === "multiplelist"
                ? []
                : "",
            error: "",
            validations: lstOfficialField?.[i]?.objValidations ? lstOfficialField?.[i]?.objValidations : {},
            hidden: objHidden || {},
            objLanguage: {
              isMultiLanguage: lstOfficialField?.[i]?.objLanguage === false ? false : true,
              language:
                lstOfficialField?.[i]?.objLanguage === false ? { eng: appLanguage.eng } : !lstOfficialField?.[i]?.objLanguage ? null : lstOfficialField?.[i]?.objLanguage,
            },
            options: lstOfficialField?.[i]?.lstOption ? lstOfficialField?.[i]?.lstOption : [],
          },
          labels: {
            label: lstOfficialField?.[i]?.strLabel ? lstOfficialField?.[i]?.strLabel : dictionary?.formapi?.[lstOfficialField?.[i]?.strName]?.label || "",
            placeholder: lstOfficialField?.[i]?.strPlaceholder
              ? lstOfficialField?.[i]?.strPlaceholder
              : dictionary?.formapi?.[lstOfficialField?.[i]?.strName]?.placeholder || "",
            validationsMsg: validationsMsg,
            options: lstOfficialField?.[i]?.objOptionLang || {},
          },
        });

        lstFormControlTmp.push({
          strName: lstOfficialField?.[i]?.strName,
          controller: controller,
        });
      }

      objRef.current.lstFormControl = !blnIsAppend ? lstFormControlTmp : objRef.current.lstFormControl.concat(lstFormControlTmp);
      addListFields(lstFieldForm);
      return lstFormControlTmp;
    } catch (error) {
      appFunctions.logMessage("buildFormListExternal", "buildFormListExternal-Error", error);
    }
  };

  const addListFields = (fields = []) => {
    const newFormState = controller.formState;
    fields.forEach((field) => {
      newFormState.fields[field?.fieldName] = { ...newFormState.fields[field?.fieldName] };

      newFormState.fields[field?.fieldName] = {
        ...field?.fields,
      };

      newFormState.labels[field?.fieldName] = {
        ...field?.labels,
      };
    });

    controller.setFormState({ ...newFormState });
  };

  const deleteFields = (fieldName) => {
    let lstFormControl = Array.isArray(objRef.current.lstFormControl) ? objRef.current.lstFormControl : [];
    let lstNewFormControl = lstFormControl.filter((field) => String(field.strName) !== String(fieldName));

    objRef.current.lstFormControl = lstNewFormControl;
    delete controller?.formState?.fields?.[fieldName];
    delete controller?.formState?.labels?.[fieldName];

    controller.setFormState({ ...controller.formState });

    return lstNewFormControl;
  };

  const deleteForm = () => {
    objRef.current.lstFormControl = [];
    controller.formState.fields = {};
    controller.formState.labels = {};
    controller.setFormState({ ...controller.formState });
  };

  const resetForm = (fieldName = null) => {
    if (fieldName) {
      if (controller.formState.fields?.[fieldName]) {
        if (
          typeof controller.formState.fields?.[fieldName]?.value === "object" &&
          (controller.formState.fields?.[fieldName].control === "text" || controller.formState.fields?.[fieldName].control === "textarea")
        ) {
          controller.formState.fields[fieldName].value = JSON.parse(JSON.stringify(controller.formState.fields?.[fieldName].value));
          Object.entries(controller.formState.fields?.[fieldName].value).forEach(([langKey, langValue]) => {
            controller.formState.fields[fieldName].value[langKey] = "";
          });
        } else if (controller.formState.fields?.[fieldName].control === "checkbox" || controller.formState.fields?.[fieldName].control === "multiplelist") {
          controller.formState.fields[fieldName].value = [];
        } else if (
          controller.formState.fields?.[fieldName].control === "list" ||
          controller.formState.fields?.[fieldName].control === "switch" ||
          controller.formState.fields?.[fieldName].control === "radio"
        ) {
          controller.formState.fields[fieldName].value = {};
        } else {
          controller.formState.fields[fieldName].value = "";
        }
      }
    } else {
      Object.entries(controller.formState.fields).forEach(([fieldName, objValue]) => {
        if (typeof objValue?.value === "object" && (objValue.control === "text" || objValue.control === "textarea")) {
          objValue.value = JSON.parse(JSON.stringify(objValue.value));
          Object.entries(objValue.value).forEach(([langKey, langValue]) => {
            objValue.value[langKey] = "";
          });
        } else if (objValue.control === "checkbox" || objValue.control === "multiplelist") {
          objValue.value = [];
        } else if (objValue.control === "list" || objValue.control === "switch" || objValue.control === "radio") {
          objValue.value = {};
        } else {
          objValue.value = "";
        }
      });

      controller.setFormState({ ...controller.formState });
    }
  };

  return {
    isFormValid: controller.isFormValid,
    resetField: (fieldName = null) => controller.resetForm(fieldName),
    getValue: (strFieldName = null) => getValueErrorWrapper(!strFieldName ? strName : strFieldName),
    getAllValues: () => ClientFunction.checkParseObject(controller.getCurrentFormValues()),
    setOptions: (lstOptionExt, objOptionLang = {}) => {
      controller.setOptions(strName, lstOptionExt, objOptionLang);
    },
    setValue: (value) => {
      controller.setValue(
        strName,
        value ? value : String(strControl).toLocaleLowerCase() === "checkbox" || String(strControl).toLocaleLowerCase() === "multiplelist" ? [] : ""
      );
      /* setValueManually(
        value,
        strName,
        strControl,
        controller?.formState?.fields?.[strName]?.options,
        controller?.formState?.fields?.[strName]?.objLanguage?.isMultiLanguage
      ); */
    },

    strName: strName,
    controller: controller,

    //////////////////////////////////////////////////////////////////////////////////////////
    setFormList: (lstNewFormField, blnIsAppend) => buildFormListExternal(lstNewFormField, blnIsAppend),
    deleteFields: deleteFields,
    deleteForm: deleteForm,
    lstHandler: objRef?.current?.lstFormControl,
    ...getObjHandler(),
  };
}

/**
 * @param {{ objHandler : FormReturn } & FormControlTypeInput } param0
 */
export function FormField({ objHandler, ...rest }) {
  useEffect(() => { }, [objHandler, objHandler?.controller]);

  return <ViewFormField fieldName={objHandler?.strName} controller={objHandler.controller} iconColor={App_Primary_Color} {...rest} />;
}

function resolve(path, obj) {
  try {
    return path.split(".").reduce(function (prev, curr) {
      return prev ? prev[curr] : null;
    }, obj);
  } catch {
    return null;
  }
}
