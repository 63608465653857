//#region ******************************************************* //
  /*
    *This is the first page view when open the website . called from Router.js
  */
//#endregion ************************************************* //

import React from 'react'
import Home from './public/home/Home'
import useCheckSystemInfo from '../../../appHelper/appHook/useCheckSystemInfo'
import useCheckCat from '../../../appHelper/appHook/useCheckCat';

function RouteLandingPage() {
  useCheckSystemInfo();
  useCheckCat()
  return (
    <React.Fragment>
      <Home />
    </React.Fragment>
  )
}

export default RouteLandingPage