import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Avatar, Container, Grid, Icon, Typography } from '@cbmisorg/client-app'
import React from 'react'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary'

import img1 from "../../../../../../assets/landing-images/tabInner1.png"
import img4 from "../../../../../../assets/landing-images/tabInner_dm.png"
import img3 from "../../../../../../assets/landing-images/tabinner2.png"
import img2 from "../../../../../../assets/landing-images/tab6_image.png"
import img5 from "../../../../../../assets/landing-images/cfisa-avatar.png"
import img6 from "../../../../../../assets/landing-images/edu.jpg"
import { App_Red_Color } from '../../../../../../appHelper/appColor'

const lstImg = [img1,img2,img3,img4,img5,img6];

function Services({lang}) {
    const labels = dictionary?.components?.public?.home?.services

    return (
        <React.Fragment>
            <Container py-10 id="services">
                <Grid container spacing={0}  justify={'center'}>
                    <Grid item xs="12">
                        <Typography as="h4" pb-8>
                            {labels?.title?.[lang]}
                        </Typography>
                    </Grid>
                    {
                        labels.lstServices.map((item,index)=>{
                            return(
                                <Grid item xs="12" key={index} alignSelf={'flex-start'}>
                                    <Accordion dir={lang==="arb"?"rtl":"ltr"}>
                                    <AccordionTab sx={{border:"2px solid var(--cbmis-primary) !important", borderBottom:"1px solid var(--cbmis-primary) !important"}}>
                                    <AccordionSummary sx={{borderBottom:"1px solid var(--cbmis-primary) !important"}}>
                                                <Grid container>
                                                    <Grid item>
                                                        <Avatar src={lstImg?.[index]} sx={{width:"35px !important",height:"35px !important"}}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography as="body1">{item?.name?.[lang]}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                            <Grid container spacing="2" py-5>
                                                <Grid item xs="12" >
                                                    <Typography as="body1">
                                                        {item?.describe?.[lang]}
                                                    </Typography>
                                                </Grid>
                                                {
                                                    item?.lst?.map((point,ind)=>{
                                                        return(
                                                            <Grid item xs="12"  container key={ind}>
                                                                <Grid item alignSelf={"flex-start"}>
                                                                    <Icon icon="check" color={App_Red_Color} className="iconAccordion"/>
                                                                </Grid>
                                                                <Grid item xs="10" lg="11">
                                                                    <Typography as="body2">{point?.[lang]}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </AccordionDetails>
                                        </AccordionTab>
                                    </Accordion>
                                </Grid>
                            )
                            })
                    }
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default Services