import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { Badge, Container, Grid, Icon, Typography, useNavigate, Box, Alert } from "@cbmisorg/client-app";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { getNextStrURL, logMessage } from "../../../../appHelper/appFunctions";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";

const strPageInfo = "@src/components/stakeholders/admin/account/Account.js";
const tblStatistic = generateQueries("tblStatistic")

const navigates = [
  { icon: "peopleOutlined", label: dictionary?.components?.mnageRequestJoin?.title, url: objAppRouting?.Manage_RequestJoin?.url, featureID: objAppRouting?.Manage_RequestJoin?.featureID, badge: "intCountAllStudentsRole" },    
  //{ icon: "ContactMail", label: dictionary?.components?.contact?.mngTitle, url: objAppRouting?.Contact?.url, featureID: objAppRouting?.Contact?.featureID, badge: "intCountMail" },
  //{ icon: "Subscriptions", label: dictionary?.components?.supscrption?.title, url: objAppRouting?.Subscription?.url, featureID: objAppRouting?.Subscription?.featureID },    
  { icon: "campaign", label: dictionary?.components?.mngAnnouncement?.title, url: objAppRouting?.MngAnnouncement?.url, featureID: objAppRouting?.MngAnnouncement?.featureID, badge:"intCountAnnouncement" },
  { icon: "liveTv", label: dictionary?.components?.mngAnnouncement?.titleLiveSession, url: objAppRouting?.MngMeeting?.url, featureID: objAppRouting?.MngMeeting?.featureID, badge:"intCountMeeting" },
  { icon: "AccountCircle", label: dictionary?.components?.manageMembers?.profile, url: objAppRouting?.profile?.url, featureID: objAppRouting?.profile?.featureID },  
  // { icon: "school", label: dictionary?.components?.joinSymposium?.titleJoin, url: objAppRouting?.Mng_Join_Training?.url, featureID: objAppRouting?.Mng_Join_Training?.featureID, badge: "intCountJoinToSymposium" },
  //{ icon: "Settings", label: dictionary?.components?.manageSystem?.title, url: objAppRouting?.ManageSystem?.url, featureID: objAppRouting?.ManageSystem?.featureID },
];

function AdminAccount() {
  useCheckCat();

  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  // useCheckUserLoginDevice()
  const { getNewSystemInfo } = useCheckSystemInfo({ notHook: true, isGetCount: true });
  const lang = appState?.clientInfo?.strLanguage;
  const lstUserFeature = appState?.userInfo?.lstUserFeature || [];
  const [isLoading, setIsLoading] = useState(false);
 
  useEffect(() => {
    // const accountUrl = getNextStrURL();
    // if (accountUrl !== objAppRouting?.Account?.url) {
    //   navigate(accountUrl, { replace: true });
    // }
    getNewSystemInfo();
  }, []);

  
  const functionClickedNav = (item) => async () => {
    if(!item?.badge){
      navigate(item.url)
      return;
    }
    if (isLoading) {
        Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
      return;
    }

    setIsLoading(true);
    const result = await tblStatistic(strPageInfo,appState,"itcAppUpdate",{
      objectInput:{
        [item?.badge]: 0,
        dtmCreatedDate: new Date().toUTCString(),
      },
      objectCondition:{
        bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appState?.userInfo.bigSystemID,
      }
    })
    if (!result?.blnIsRequestSuccessful) {
      logMessage(result?.response, "");
    }
    setIsLoading(false);
    navigate(item.url)


  };

  return (
    <React.Fragment>
      <Container py-10>
        <Grid container justify={"center"} spacing={1}>
          {navigates
            ?.filter((item) => lstUserFeature?.includes(item?.featureID) || !item?.featureID)
            ?.map((item, index) => {
              return (
                <Grid item xs="6" sm="4" md="3" lg="2" key={index} className="navAccount">
                  <Box className="boxNav" onClick={functionClickedNav(item)}>
                    <Grid container justify={"center"}>
                      <Grid item xs="12">
                        {item?.badge && appState?.clientInfo?.objSystemInfo?.[item?.badge] ? (
                          <Badge content={appState?.clientInfo?.objSystemInfo?.[item?.badge]}>
                            <Icon icon={item?.icon} color="primary" />
                          </Badge>
                        ) : (
                          <Icon icon={item?.icon} color="primary" />
                        )}
                      </Grid>
                      <Grid item xs="12">
                        <Typography as="caption" className="textcenter">
                          {item?.label?.[lang]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default AdminAccount;
