import { useContext, useEffect } from "react";
import * as appVariables from "../appVariables";
import * as appFunctions from "../appFunctions";
import { ClientFunction } from "@cbmisorg/client-app";
import { AppContext } from "../../configuration/contextapi/AppContext";
import { generateQueries, objTableAttributes } from "../appQuery/GenerateQuery";
import mngAnnouncementCtrl from "../../components/stakeholders/admin/mngAnnouncement/mngAnnouncement.controller";

const strPageInfo = "@src/appHelper/hooks/useCheckSystemInfo.js";
const tblSystem = generateQueries("tblSystem");
const tblStatistic = generateQueries("tblStatistic");

export default function useCheckSystemInfo(props) {
  const { appState } = useContext(AppContext);

  useEffect(() => {
    if (!props?.notHook) {
      checkHookValidity();
    }
  }, []);

  async function checkHookValidity() {
    try {
      let intLastChecked = ClientFunction.getDifferenceBetweenTwoDate(appState.clientInfo.dtmLastCheckedSystemInfo, new Date(), "hour");
      const checked = props?.isGetCount ? intLastChecked < appVariables.System_Info_Count_HoursToCheck : intLastChecked < appVariables.System_Info_HoursToCheck;
      if (checked && !Number.isNaN(Number(intLastChecked)) && Object.keys(appState?.clientInfo?.objSystemInfo || {}).length > 0) {
        return;
      }

      await getNewSystemInfo();
    } catch (error) {
      appFunctions.logMessage(strPageInfo, "checkHookValidity-ERROR", error);
    }
  }

  async function getNewSystemInfo(appDispatch = null) {
    try {
      const result = await tblSystem(strPageInfo, appState, "itcAppFindOne", {
        objectCondition: {
          bigSystemID: appState?.userInfo?.bigSystemID || appVariables.ITC_System_ID,
        },
        arrAttributes: props?.isGetCount ? objTableAttributes?.tblSystem?.full : ["bigSystemID", "strSystemUrlCode", "jsnSystemName", "blnIsActive"],
      });
      if (!result?.blnIsRequestSuccessful) {
        return;
      }

      let objSystemInfo = {};
      if (props?.isGetCount) {
        const resultStatistic = await tblStatistic(strPageInfo, appState, "itcAppFindOne", {
          objectCondition: {
            bigSystemID: appState?.userInfo?.bigSystemID || appVariables.ITC_System_ID,
          },
          arrAttributes: objTableAttributes.tblStatistic.full,
        });
        if (!resultStatistic?.blnIsRequestSuccessful) {
          return;
        }

        let { lstMeeting, lstNotification } = await mngAnnouncementCtrl({ appDispatch, appState }).getCounterAnnouncementToDisplay(result?.response?.jsnLstAnnouncement);

        objSystemInfo = {
          ...resultStatistic.response,
          intCountAllStudentsRole:
            resultStatistic.response?.intCountJoinEntrepreneur + resultStatistic.response?.intCountJoinManager + resultStatistic.response?.intCountJoinStudent,
          intCountAnnouncement: lstNotification?.length,
          intCountMeeting: lstMeeting?.length,
        };
      }

      objSystemInfo = { ...objSystemInfo, ...result?.response };

      if (JSON.stringify(appState?.clientInfo?.objSystemInfo) !== JSON.stringify(objSystemInfo)) {
        appState.clientInfo.objSystemInfo = objSystemInfo;
        appState.clientInfo.dtmLastCheckedSystemInfo = ClientFunction.getCurrentDate();
        appFunctions.setAppLocalStorage(appState);

        if (appDispatch) {
          appDispatch();
        }
      } else {
        changeLastCheckedDateNoRender();
      }
    } catch (error) {
      appFunctions.logMessage(strPageInfo, "getNewSystemInfo-ERROR", error);
    }
  }

  function changeLastCheckedDateNoRender() {
    try {
      appState.clientInfo.dtmLastCheckedSystemInfo = ClientFunction.getCurrentDate();
      appFunctions.setAppLocalStorage(appState);
    } catch {}
  }

  return { getNewSystemInfo };
}
