import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../../configuration/contextapi/AppContext';
import { Container, Grid, Icon,  Paper, Typography, Avatar, Line, useLocation, useNavigate, ClientFunction, Loader, } from '@cbmisorg/client-app';
import { App_Primary_Color } from '../../../../appHelper/appColor';
import { CtrlContact } from './controler/CtrlContact';
import { dictionary } from '../../../../appHelper/appLanguage/dictionary';
import ShredHeader from '../../../sharedUi/header/ShredHeader';

const classes={
  heading:{
    background:App_Primary_Color,
    color:"#fff"
  }
}
function RouteViewContact() {
  const { appState, appDispatch } = useContext(AppContext);
  const lang = appState.clientInfo.strLanguage
  const labels = dictionary?.components?.contact
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const {state} = useLocation()
  const [stateInfo, setStateInfo] = useState({
      blnIsInitDone:false,
  })

  const handlers = CtrlContact(appState,isLoading, setIsLoading,stateInfo, setStateInfo,navigate);

  const handelDelete=async()=>{
    if(state?.mailSelected){
      await handlers.delateMailConfirmation(state?.mailSelected)
    }
  }

  useEffect(() => {  
    return () => {
      if(!stateInfo?.blnIsInitDone && state?.mailSelected){
        handlers?.viewMail(state?.mailSelected, appDispatch)
      }
    }
  }, [])

  return (
    <React.Fragment>
      <ShredHeader title={labels?.title?.[lang]} blnBottomBar={false} lang={lang}/>
      <Container dir="lrt" py-3>
      {
        isLoading?<Loader backdrop={true} color={App_Primary_Color}/>:null
      }
       <Grid container justify={'center'}>
          <Grid item xs="12">
            <Paper sx={{minHeight:"85vh",maxHeight:"85vh",overflowY:"auto"}} p-0 dir="lrt">
              <Grid container sx={classes?.heading} px-2 justify={'space-between'} dir="ltr">
               <Grid item justify={'end'}>
                  <Typography as="body2">{ClientFunction?.getDateUSFormat(state?.mailSelected?.dtmCreatedDate)}</Typography>
                </Grid>
                <Grid item>
                   <Icon icon={"delete"} size="24px" onClick={handelDelete}/>
                </Grid>
               
              </Grid>
                  <Grid container p-2 dir="ltr">
                    <Grid item xs="9" container p-0>
                      <Grid item>
                        <Avatar color={App_Primary_Color} sx={{width:"35px",height:"35px"}}>{String(state?.mailSelected?.jsnMailInfo?.strFullName)?.charAt(0)}</Avatar>
                      </Grid>
                      <Grid item  container spacing={0}>
                        <Grid item  xs="12">
                          <Typography as="subtitle2">{state?.mailSelected?.jsnMailInfo?.strFullName}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography as="body2" pt-0>{state?.mailSelected?.jsnMailInfo?.strEmail}</Typography>
                        </Grid>
                        <Grid item>
                          <Icon icon="copyAll" size="16px" onClick={()=>ClientFunction?.copyTextToClipboard(state?.mailSelected?.jsnMailInfo?.strEmail)}/>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs="3" container spacing={0} justify={'end'}>
                        <Grid item xs="12" container justify={'end'}>
                          <Grid item>
                              <Icon icon="reply" color={App_Primary_Color} onClick={()=>window.open(`mailto:${state?.mailSelected?.jsnMailInfo?.strEmail}`)} />
                          </Grid>
                        </Grid>
                    </Grid>
                  </Grid>
                    <Line />
                  <Grid container p-2 spacing={2}>
                      <Grid item>
                        <Typography as="subtitle2">{state?.mailSelected?.jsnMailInfo?.strSubject}</Typography>
                      </Grid>
                      <Grid item xs="12">
                        <Typography as="body2">{state?.mailSelected?.jsnMailInfo?.strMessage}</Typography>
                     </Grid>
                  </Grid>
            </Paper>
         </Grid>
       </Grid>
      </Container>

    </React.Fragment>
  )
}

export default RouteViewContact