export const App_Primary_Color = "#0371a0"// "#B08730" 
export const App_Second_Color = "#CEE1EA" // "#EAE3DF"
export const App_Beige_Color1 = "#D9B5A1" 
export const App_Beige_Color3 ="#CEE1EA" // "#EAE3DF"
export const App_Beige_Color4 ="#d7e9f7" 
export const App_Light_Color = "#Fefefe"
export const App_orang_color= "#EC9403";
export const App_orang_light_color= "#fce5bf";

export const App_Dark_Color = "#000"


export const App_White = "#FFFFFF"
export const App_Text_Color = "#6B6C70"
export const App_Black = "#000000"




// export const App_orang_color= "#EC9403";
// export const App_orang_color= "#EC9403";
// export const App_orang_color= "#EC9403";


export const App_Gray_Color = "#989898";

export const App_Light_Gray = "#efefef";
export const App_Light_purple = "#E5D1FA";
export const App_Light_blue = "#d1e9fc";
export const App_Light_Bluewood = "#657990";
export const App_Light_green = "#DEF5E5";
export const App_Light_yellow = "#FFF4D2";
export const App_Light_orange = "#FFD1DA";

export const App_Dark_purple = "#6b0b60";
export const App_Dark_blue = "#044d88";
export const App_Dark_Bluewood = "#2B3C4F";
export const App_Dark_green = "#027a5a";
export const App_Dark_yellow = "#ffd24c";
export const App_Dark_orange = "#ff365e";

export const App_Red_Color="#992941";
export const App_Parable_Color="#6056de";
export const App_Orange_Color="#fe9400";
export const App_Green_Color = "#4bd965"
export const App_Green_Color2 = "#0ABAB5"
export const App_Blue_Color = "#007afd"