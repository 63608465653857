
export const appLanguage = {
  arb: { blnActive: true, blnIsMandatory: true, label: "العربية" },
  eng: { blnActive: true, blnIsMandatory: true, label: "English" },
};

export const appLangOrientation = {
  arb: "Right",
  eng: "Left",
};

export const appLangDirection = {
  arb: "rtl",
  eng: "ltr",
};
export const appLanguageDirection = {
  arb: "rtl",
  eng: "ltr",
};

export const dictionary = {
  shared: {
    webInfo: {
      name: { eng: "The private office of", arb: "المكتب الخاص" },
    },
    stackHolder: {
      admin: { eng: "Admin", arb: "الادمن" },
      owner: { eng: "Owner", arb: "المالك" },
    },
    formValidationsMsg: {
      required: { eng: "Required!", arb: "الحقل مطلوب" },
      email: { eng: "Invalid email address", arb: "البريد الإلكتروني غير صحيح" },
      onlyNumber: { eng: "Only Number", arb: "مسموح فقط أرقام" },
    },
    alertFetchNote: {
      confirmedPartner: {
        eng: "Are you sure the logo has been uploaded and the required fee has been paid",
        arb: "هل أنت متأكد من أن تم رفع الشعار ودفع الرسوم المطلوبة",
      },
      response_Not_Except: {
        eng: "An error has occurred, please use valid information and try again. If you keep getting the same error notification, please contact us via email",
        arb: "حدث خطأ ، يرجى استخدام المعلومات الصحيحة والمحاولة مرة أخرى. إذا استمر ظهور نفس رسالة الخطأ ، فيرجى الاتصال بنا عبر البريد الإلكتروني",
      },
      updated_Successfully: {
        eng: "Thank you for contacting us.  Your request has been received successfully, and we will contact you shortly",
        arb: "شكرا لإهتمامك. لقد تم إستلام طلبك بنجاح و سيتم التواصل معك قريبا",
      },
      updated_Successfully2: {
        eng: "Updated Successfully",
        arb: "تم التحديث بنجاح",
      },
      added_Successfully: {
        eng: "Added Successfully",
        arb: "اضيف بنجاح",
      },
      cant_Update_Record: {
        eng: "Sorry, the update cannot be completed at this time. Please try again later",
        arb: "عذرًا ، لا يمكن حفظ معلوماتك في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقًا",
      },
      cant_Delete_Record: {
        eng: "Sorry, the delete cannot be completed at this time. Please try again later",
        arb: "عذرًا ، لا يمكن حذف المعلومات في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقًا",
      },
      cant_Save_Record: {
        eng: "Sorry, can't save your information at this time. Please try again later",
        arb: "عذرًا ، لا يمكن حفظ معلوماتك في الوقت الحالي. يرجى المحاولة مرة أخرى لاحقًا",
      },
      no_Data_To_Display: {
        eng: "Sorry, but there is no data to display yet",
        arb: "عذرًا ، لا توجد أي بيانات لعرضها حتى الآن",
      },
      fetchData_InProgress: {
        eng: "Your request has been successfully submitted and is being processed... please wait",
        arb: "تم تقديم طلبك بنجاح وتتم معالجته ... يرجى الإنتظار",
      },
      invalidLoginInfo: {
        eng: "Invalid credentials. Please try again",
        arb: "بيانات الدخول غير صحيحه. يرجى المحاوله مرة أخرى",
      },
      already_Registered: {
        eng: "You have already registered using this email address",
        arb: "لقد قمت بالتسجيل بالفعل باستخدام عنوان البريد الإلكتروني هذا",
      },
      already_subscrbtion: {
        eng: "You have already subscribed using this email address",
        arb: "لقد قمت بالإشتراك من قبل باستخدام هذا البريد الإلكتروني ",
      },
      email_Already_Used: {
        eng: "This email is already in use",
        arb: "هذا البريد الإلكتروني استخدم من قبل",
      },
      phone_Already_Used: {
        eng: "This phone number is already in use",
        arb: "رقم الهاتف استخدم من قبل",
      },
      email_Not_Used: {
        eng: "This email is not used",
        arb: "هذا البريد الإلكتروني لم يُستخدم من قبل",
      },
      Already_Jion: {
        eng: "We have previously registered as a programmer development supporter. For any inquiries, please contact us",
        arb: "تم التسجيل كداعم لتطوير المبرمجين من قبل. لأي استفسار يرجى التواصل معنا",
      },
      email_CantSend: {
        eng: "Sorry, can't send your email at this time. Please try again later",
        arb: "عذرًا ، لا يمكنني إرسال بريدك الإلكتروني في الوقت الحالي. الرجاء إعادة المحاولة لاحقًا",
      },
      exceed_Limit_Of_Email: {
        eng: "Thank you. we received your email",
        arb: "شكرا لك ، لقد تلقينا بريدك الإلكتروني",
      },
      requeioUploadFile: {
        eng: "Please make sure upload required files",
        arb: "يرجى التأكد من تحميل الملفات المطلوبة",
      },
      requeioUploadImage: {
        eng: "Please make sure upload main image",
        arb: "يرجى التأكد من تحميل الصورة الرئيسية",
      },
      invalid_Field: {
        eng: "Please make sure all required fields are filled out correctly",
        arb: "يرجى التأكد من ادخال جميع الحقول المطلوبة بشكل صحيح",
      },
      invalid_Field_Email: {
        eng: "Please enter your email address",
        arb: "الرجاء إدخال بريدك الإلكتروني",
      },
      cantFindRecords: {
        eng: "Sorry, But there nothing to view",
        arb: "عذرًا ، ولكن لا يوجد شيء لعرضه",
      },
      cantUploadFile: {
        eng: "Sorry, But Can't upload file at this time please try again later",
        arb: "عذرًا ، ولكن لا يمكن تحميل الملف في الوقت الحالي ، يرجى المحاولة مرة أخرى في وقت لاحق",
      },
      cantDownloadFile: {
        eng: "Sorry, But Can't download file at this time please try again later",
        arb: "عذرًا ، ولكن لا يمكن تنزيل الملف في الوقت الحالي ، يرجى المحاولة مرة أخرى في وقت لاحق",
      },
      contactUs: {
        eng: "تم ارسال طلبك، سيتم التواصل معك في اقرب وقت",
        arb: "Your request has been sent. We will contact you as soon as possible",
      },
      emailVerificationSent: { eng: "Verification code sent to your inbox", arb: "تم إرسال رمز التحقق إلى صندوق الوارد الخاص بك" },
      emailCantSend: {
        eng: "Sorry, can't send your email at this time. Please try again later",
        arb: "عذرا، لا يمكن الإرسال للبريد الإلكتروني الخاص بك في هذا الوقت. الرجاء معاودة المحاولة في وقت لاحق",
      },
    },
    confirmationMsg: {
      subsecrbtionConf: {
        eng: "An email will be sent to you periodically with the latest news from the association. Do you want to continue?",
        arb: "سيتم إرسال بريد إلكتروني لك بشكل دوري بآخر أخبار الجمعية، هل تريد الإستمرار؟",
      },
      changeStatusTMS: { eng: "Are you sure ?", arb: "هل أنت متأكد  ؟" },
      changeUserInfo: { eng: "Are you sure about save the new information?", arb: "هل أنت متأكد من حفظ المعلومات الجديدة؟" },
      unLockUser: { eng: "Are you sure to unlock this user ?", arb: "هل أنت متأكد من فك حظر هذا المستخدم ؟" },
      deleteSystem: { eng: "Are you sure to delete this system ?", arb: "هل أنت متأكد من حذف هذا النظام ؟" },
      deleteItem: { eng: "Are you sure to delete this item ?", arb: "هل أنت متأكد من حذف هذا العنصر ؟" },
      deleteMail: { eng: "Are you sure to delete this email ?", arb: "هل أنت متأكد من حذف هذا البريد الإلكتروني ؟" },
      deleteAllMail: { eng: "Are you sure to delete the selected emails?", arb: "هل أنت متأكد من حذف هذه الرسائل الإلكترونية؟ " },
      deleteFeedback: { eng: "Are you sure to delete this feedback ?", arb: "هل أنت متأكد من حذف هذا التقييم ؟" },
      publishFeedback: { eng: "Are you sure to publish this feedback ?", arb: "هل أنت متأكد من نشر هذا التقييم ؟" },
      unpublishFeedback: { eng: "Are you sure to unpublish this feedback ?", arb: "هل أنت متأكد من عدم نشر هذا التقييم ؟" },

      approve: { eng: "Are you sure to make the applicant a member of the association?", arb: "هل أنت متأكد من جعل مقدم الطلب عضواً في الجمعية؟" },
      approveActive: { eng: "Are you sure to activate the subscription?", arb: "هل أنت متأكد من تفعيل الاشتراك؟" },

      deletemember: { eng: "Are you sure to delete this user?", arb: "هل أنت متأكد من حذف هذا المستخدم ؟" },
      deleteActivity: { eng: "Are you sure to delete this activity?", arb: "هل أنت متأكد من  حذف هذا النشاط ؟" },

      publish: { eng: "Are you sure to publish this activity?", arb: "هل أنت متأكد من اعادة نشر هذا النشاط؟" },
      archaic: { eng: "Are you sure to archive the activity?", arb: "هل أنت متأكد من أرشفة هذا النشاط؟" },

      logout: { eng: "Are you sure to log out?", arb: "هل أنت متأكد من تسجيل الخروج؟" },
      yes: { eng: "Yes", arb: "نعم" },
      yesSure: { eng: "Yes, I'm Sure", arb: "نعم أنا متأكد" },
      yesMove: { eng: "Transitioning to the request page", arb: "الانتقال لصفحة الطلب" },
      no: { eng: "No", arb: "لا" },
    },
    btn: {
      readMore: { eng: "Read More", arb: "اقرأ المزيد" },
      save: { eng: "Save", arb: "حفظ" },
      send: { eng: "Send", arb: "ارسال" },
      partnerInfo: { eng: "Partner Information ", arb: "معلومات المسؤول" },

      view: { eng: "View", arb: "عرض" },

      viewGovID: { eng: "View Government ID", arb: "عرض الهوية الشخصية" },
      viewRest: { eng: "View Bank receipt", arb: "عرض الوصل البنكي" },

      approve: { eng: "Approve", arb: "موافقة" },
      regect: { eng: "Reject", arb: "رفض" },
      resubmission: { eng: "Resubmission required files", arb: "إعادة تقديم الملفات المطلوبة" },
      addBtn: { eng: "Add", arb: "إضافة" },
      editBtn: { eng: "Edit", arb: "تعديل" },
      closeBtn: { eng: "Close", arb: "إغلاق" },
      otherBtn: { eng: "Other", arb: "أخرى" },
    },
    account: {
      gender: {
        male: { arb: "ذكر", eng: "Male" },
        female: { arb: "أنثى", eng: "Female" },
      },
      country: {
        kwt: { arb: "كويتي", eng: "Kuwaiti" },
        notKwt: { arb: "غير كويتي", eng: "Non-Kuwaiti" },
      },
      degreey: {
        diploma: { arb: "شهادة دبلوم", eng: "Diploma" },
        bachelor: { arb: "بكالوريوس", eng: "Bachelor's" },
        masters: { arb: "ماجستير", eng: "Masters" },
        doctorate: { arb: "دكتوراه", eng: "Doctorate" },
      },
    },
    ui: {
      fileLabelRequired: { label: { eng: "File Label (e.g. Government ID, Passport Image, Certificates)", arb: "وسم الملف (مثل: صورة عن الهوية, جواز السفر, شهادات)" } },
      addRequiredFile: { label: { eng: "Add a new file required for upload", arb: "إضافة ملف جديد مطلوب تحميله" } },
      editRequiredFile: { label: { eng: "Edit information for  the file required for upload", arb: "تحرير معلومات الملف المطلوب للتحميل" } },
      viewFiles: { eng: "View Files", arb: "عرض الملفات" },
      filesNum: { eng: "Files", arb: "ملفات" },
      fileNum: { eng: "File", arb: "ملف" },
      noFileYet: { eng: "No Files Yet!", arb: "لا توجد ملفات حتى الآن!" },
      mngFiles: { eng: "Mnage Files", arb: "إدارة الملفات" },
      uploadFiles: { eng: "Upload File", arb: "رفع الملفات" },
      uploadFilesBtn: { eng: "Upload", arb: "رفع" },
    },
    accountSection: {
      // home: { eng: "Home", arb: "الرئيسية" },
      account: { eng: "My Account", arb: "حسابي" },
      profile: { eng: "Profile", arb: "ملف شخصي" },
      // cardid: { eng: "Card ID", arb: "بطاقة الانضمام" },
      // settings: { eng: "Settings", arb: "الإعدادات" },
    },
    buttons: {
      sendBtn: {
        eng: "Send",
        arb: "ارسال"
      },
      submitBtn: {
        eng: "Submit",
        arb: "حفظ"
      },
      installBtn: {
        eng: "Install Now",
        arb: "تنزيل الآن"
      },
      addNewBtn: {
        eng: "Add New",
        arb: "جديد"
      },
      addBtn: {
        eng: "Add",
        arb: "إضافة"
      },
      editBtn: {
         eng: "Edit",
         arb: "تعديل"
      },
      contactUsBtn:{
        eng:"ContactUs",
        arb:"تواصل معنا"
      },
      readMoreBtn:{
        eng:"find out more",
        arb:"اقرأ المزيد"
      },
      joinUsBtn:{
        eng:"Join us",
        arb:"انضم الينا"
      }
    },
  },
  components: {
    public: {
      appName: {
        eng: "Artificial Intelligence for Things Association",
        arb: "جمعية شباب المستقبل",
      },
      login: {
        heading: { eng: "Login", arb: "تسجيل الدخول" },
        reg: { eng: "Registration", arb: "إنشاء حساب" },
        forgetPassword: { eng: "Forget Password", arb: "نسيت كلمة المرور" },
        btnLogin: { eng: "Login", arb: "دخول" },
      },
      ForgetPassword: {
        title: { eng: "ForgetPassword", arb: "نسيت كلمة المرور" },
        subTitle1: { eng: "Enter your email to received the verification code", arb: "ادخل بريدك الالكتروني لاستقبال رمز التحقق" },
        subTitle2: { eng: "Please enter the code sent to your email in the designated field here", arb: "يرجى إدخال الرمز المرسل إلى بريدك الإلكتروني في الحقل المخصص هنا" },
        subTitle3: { eng: "Enter the new password", arb: "ادخل كلمة المرور الجديدة" },
        btnSubmit: { eng: "Submit", arb: "ارسال" },
      },
      join: {
        title: { eng: "Join us", arb: "انضم إلينا" },
        partnerTitle: { eng: "Join us in our success", arb: "شاركنا النجاح" },
        thank: {
          eng: "Thank You for your interest in joining AAIOT!",
          arb: "شكرًا لك على اهتمامك بالإنضمام للجمعية !",
        },
        partnerSubtitle: {
          eng: "Contribute with us to building a prosperous future by nurturing a distinguished generation of leading programmers.",
          arb: "ساهم معنا في بناء مستقبل مزدهر من خلال تنمية جيل متميز من المبرمجين المتمكنين",
        },
        personalInfo: { eng: "Personal Information", arb: "المعلومات الشخصية" },
        partnerInfo: { eng: "Partner Information", arb: "معلومات الجهة الداعمة" },
        RequrementInfo: { eng: "Joining requirements information", arb: "معلومات متطلبات الإنضمام" },
        registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        logoNote: { eng: "Upload the logo that will be displayed on the home page", arb: "تحميل الشعار الذي سيعرض في الصفحة الرئيسية" },

        btnReg: { eng: "Submit", arb: "ارسال" },
        btnPartner: { eng: "Submit", arb: "ارسال" },
        trainee: { eng: "Trainee", arb: "متدرب" },
        trainingNumber: { eng: "Training Number", arb: "عدد المتدربيين المراد دعمهم" },
        logo: { eng: "Logo", arb: "شعار" },
      },
      joinTrainee: {
        title: { eng: "Join us", arb: "انضم إلينا" },
        subtitle: {
          eng: "We appreciate your interest in joining us. We will help you make your way in the world of programming through advanced training programs and access to a set of software tools that support programmers in achieving their goals.",
          arb: "نقدر اهتمامك بالانضمام إلينا. سنساعدك في شق طريقك في عالم البرمجة من خلال برامج تدريبية متقدمة والوصول إلى مجموعة من الأدوات البرمجية التي تدعم المبرمجين في تحقيق أهدافهم",
        },
        registrationInfo: { eng: "Registration Information", arb: "معلومات التسجيل" },
        personalInfo: { eng: "Personal Information", arb: "المعلومات الشخصية" },
        btnReg: { eng: "Join", arb: "إنضم" },
      },

      footer: {
        contactUs: { eng: "Contact us", arb: "تواصل معنا" },
        contact: { eng: "Contact", arb: "إتصل بنا" },
        location: { eng: "Our Location", arb: "موقعنا" },

        home: { eng: "Home", arb: "الرئيسية" },
        account: { eng: "My Account", arb: "حسابي" },
        settings: { eng: "Settings", arb: "الإعدادات" },

        about: { eng: "About", arb: "تعرف على الجمعية" },
        informed: { eng: "Be Informed", arb: "كن على إطلاع" },
        newsletter: { eng: "Newsletter", arb: "النشرة الإخبارية" },
        presidentSpeech: { eng: "President's speech", arb: "كلمة الرئيس" },
        partners: { eng: "Partners", arb: "شركائنا" },
        itc: { eng: "Our Initiative", arb: "مبادرتنا" },
        newsletterAlert: { eng: "Thank you, your subscription was successful.", arb: "شكرًا لك، لقد تم اشتراكك بناجح." },
        newsletterBody: {
          eng: "Subscribe to the newsletter to receive all the latest news from the Artificial Intelligence for Things Association in real-time.",
          arb: "إشترك بالنشرة البريدية ليصلك كل جديد أخبار جمعية الذكاء الإصطناعي للأشياء أول بأول",
        },
        copyRight: { eng: "©2024 AAIOT All rights reserved.", arb: "©جميع الحقوق محفوظة لجمعية الذكاء الإصطناعي للأشياء 2024" },
      },
      home:{
        whyChoose:{
          title:{arb:"لماذا تختار",eng:"Why Choose"},
          subtitle:{arb:"CBM Integrated Software Inc.",eng:"CBM Integrated Software Inc."},
          lst:[
            {
              heading:{arb:"أسعار تنافسية",eng:"Competitive pricing"},
              describe:{arb:"أسعارنا معقولة وتنافسية للغاية. نحن نعمل مع نماذج تسعير مختلفة، ونتمتع بالمرونة للعمل في حدود ميزانيتك. هدفنا هو أن نقدم لعملائنا دائمًا أفضل جودة وبأسعار معقولة.",eng:"Our rates are reasonable & highly competitive. We work with various pricing models, and flexible to work within your budget. our goal is to always provide our clients with the best quality reasonably priced."},
            },
            {
              heading:{arb:"جودة لا مثيل لها",eng:"Unparallel Quality"},
              describe:{arb:"الجودة لا تأتي صدفة أبدًا، فهي دائمًا نتيجة جهد ذكي. لدينا فريق ضمان الجودة منفصل يعمل بالتوازي مع فريق التطوير لدينا مع أفضل تجربة UI/UX.",eng:"Quality is never an accident, it’s always the result of intelligent effort. We have a separate QA team that works in parallel with our development team with the best UI/UX experience."},
            },
            {
              heading:{arb:"بعد دعم التطوير",eng:"After Development Support"},
              describe:{arb:"نحن نقدم دعمًا مجانيًا (لمدة 30 يومًا حتى عام واحد) بعد التطوير والترقيات والصيانة والتحديثات الأمنية. نقوم أيضًا بصيانة ودعم التطبيقات التي طورتها شركات أخرى.",eng:"Quality is never an accident, it’s always the result of intelligent effort. We have a separate QA team that works in parallel with our development team with the best UI/UX experience."},
            },
            {
              heading:{arb:"منهجية مجربة",eng:"Proven Methodology"},
              describe:{arb:"واحدة من أعظم فوائد إطار عملنا Agile هو تحسين جودة المنتج. ومن خلال تقسيم المشروع إلى وحدات يمكن التحكم فيها، يمكن لفريق المشروع التركيز على التطوير عالي الجودة.",eng:"One of the greatest benefits of our Agile framework is improved product quality. By breaking down the project into manageable units, the project team can focus on high-quality development."},
            },
            {
              heading:{arb:"المطورين المهرة",eng:"Skilled Developers"},
              describe:{arb:"إن مطوري الويب والهواتف المحمولة المعتمدين والخبراء الذين يهتمون بالتفاصيل، ويأخذون التسليمات على محمل الجد، ويعملون مع اتصالات رائعة مع العملاء، هم أفضل صفاتنا وقوة أعضاء فريقنا.",eng:"Certified & expert web and Mobile developers who pay attention to details, take deliverables seriously, and work with great customer communication are our best qualities and our team members strength."},
            },
            {
              heading:{arb:"تطبيقات قابلة للتطوير",eng:"Scalable Apps"},
              describe:{arb:"مع أخذ الحاجة إلى التغييرات في الاعتبار، فإننا نجعل التطبيقات قابلة للتطوير بشكل كبير من خلال: اختيار المجموعة المناسبة من التكنولوجيا وقاعدة البيانات وتصميم البرامج والهندسة المعمارية. تعد قابلية التوسع عنصرًا أساسيًا في برامج المؤسسة.",eng:"Keeping the need for changes in mind, we make the apps highly scalable by: choosing the right set of technology, Database, software design and architecture. Scalability is an essential component of enterprise software."},
            },
          ]
        },
        lifecycle:{
          title:{arb:"دورة حياة تطوير البرمجيات لدينا",eng:"Software Devlopment Lifecycle"},
          subtitle:{arb:"",eng:""},
          lst:[
            {
              heading:{arb:"تخطيط",eng:"Planning"},
              describe:{arb:"يتضمن تخطيط مشاريع البرمجيات وضع تقديرات للعمل الذي سيتم تنفيذه، وتحديد الالتزامات اللازمة، وتحديد خطة أداء العمل.",eng:"Software Project Planning involves developing estimates for the work to be performed, establishing the necessary commitments, and defining the plan to perform the work."},
            },
            {
              heading:{arb:"تصميم",eng:"Design"},
              describe:{arb:"نهجنا في التصميم هو تبسيط عملية التطوير. يستمتع فريقنا بإنشاء أشياء فريدة من نوعها تكون سهلة للمستخدمين النهائيين وتحدد النظام العام.",eng:"Our design approach is to simplify the development process. Our team embrace joy of creating things unique that is easy for end-users and define overall system."},
            },
            {
              heading:{arb:"تطوير",eng:"Develop"},
              describe:{arb:"سلسلة من الخطوات لإنشاء التطبيقات. يوفر فهم طريقة تطوير البرمجيات فرصًا واسعة في صناعة تكنولوجيا المعلومات.",eng:"a series of steps to create applications. Understanding the software development method offers vast opportunities in the IT industry."},
            },
            {
              heading:{arb:"اختبار ضمان الجودة",eng:"QA Testing"},
              describe:{arb:"ويتم ذلك للتحقق من أن التطبيق يلبي احتياجات العملاء، ويفكر دائمًا ويبحث عن طرق لتحسين ما نقوم به.",eng:"This is done to verify that the app meets the customers' needs, always thinking, looking for methods to improve what we do."},
            },
            {
              heading:{arb:"تحديث",eng:"Update"},
              describe:{arb:"آلية مهمة يتم من خلالها إجراء التغييرات والتحسينات الأمنية، ويشمل هذا المفهوم الذي يبدو بسيطًا مجموعة واسعة من الممارسات.",eng:"An important mechanism by which security changes and improvements are made, and this seemingly simple concept encompasses a wide variety of practices."},
            },
          ]
        },
        vision: {
          title:{arb:"قيمنا",eng:"Benefits & Values"},
          subtitle:{arb:"",eng:""},
          lst:[
            {
                describe:{ eng:"We will be a globally respected digital technology thought leader",arb:"سنكون شركة رائدة في مجال الفكر التكنولوجي الرقمي تحظى باحترام عالمي"},
            },
            {

                describe:{eng:"Our customers will see us as pioneers in digital disruption for them",arb:"سيرى عملاؤنا أننا رواد في مجال الابتكار الرقمي بالنسبة لهم"}
            },
            {
                describe:{eng:"Our teams will be acknowledged as technology ninjas",arb:"سيتم الاعتراف بفرقنا باعتبارها نينجا التكنولوجيا"}
            },
            {
              describe:{eng:"We will touch the lives of people in societies we work in through technology",arb:"سوف ندعم حياة الناس في المجتمعات التي نعمل فيها من خلال التكنولوجيا"}
            },
          ]
        },
        thingsYouGet:{
          title:{arb:"الفوائد",eng:"Benefits"},
          subtitle:{arb:"",eng:""},
          lst:[
            {
              title:{arb:"الدعم الإبداعي",eng:"Creative Support"},
              describe:{arb:"نقوم بتحويل العلامات التجارية وتنمية الأعمال التجارية وسرد قصص العلامات التجارية والمنتجات بطريقة أكثر إبداعًا.",eng:"We transform brands, grow businesses, and tell brand and product stories in a most creative way."},
            },
            {
              title:{arb:"خلق الخبرات",eng:"Creating Experiences"},
              describe:{arb:"نحن نغطي مجموعة كبيرة من المنصات الإبداعية والمشاريع الرقمية بهدف واحد: خلق التجارب.",eng:"We cover a large range of creative platforms and digital projects with one purpose: to create experiences."},
            },
            {
              title:{arb:"استشارات المنتج",eng:"Product Consulting"},
              describe:{arb:"نحن نرشدك عبر خطوط الأنابيب التي تولد منتجات جديدة ذات إمكانات أعلى ومخاطر أقل.",eng:"We guide you through the pipelines that generate new products with higher potential and lower risk."},
            },
            {
              title:{arb:"تعزيز الأعمال",eng:"Business Boosting"},
              describe:{arb:"نحن نقدم حلولاً موفرة للطاقة ومحافظة على البيئة لعملائنا لتعزيز أعمالهم.",eng:"We provide energy-efficient and environmentally conservative solutions to our clients to boost their business."},
            },
            {
              title:{arb:"نهج استراتيجي",eng:"Strategic Approach"},
              describe:{arb:"استنادًا إلى إطار عمل استراتيجي متين وأبحاث حقيقية ذات صلة، نقوم بإنشاء نماذج أولية، وليس عروض تقديمية.",eng:"Based on solid strategic framework and real, relevant research, we create prototypes, not presentations."},
            },
            {
              title:{arb:"الاستشارات اللوجستية",eng:"Logistic Consulting"},
              describe:{arb:"نحن نعمل على جانب الشراء وجانب البيع لإعطاء عملائنا إجابات قوية والتركيز بشدة على أفضل الفرص.",eng:"We work buy side and sell side to give our clienrts hard hitting answers and focus hard on best opportunities."},
            },
          ]
        },
        services:{
          title:{arb:"خدماتنا",eng:"Our Services"},
          subtitle:{arb:"",eng:""},

          lstServices:[
            {
              name:{eng:"Software development",arb:"تطوير البرمجيات"},
              describe:{
                eng:"We provide a set of tools/packages that help developers and companies create modern and attractive user interfaces without the need to write many lines of code. These tools play a crucial role in improving the quality and effectiveness of design and improving the user experience on all types of devices, regardless of their screen size. One of the most important features of our tools is that they are designed to avoid all known security vulnerabilities, such as avoiding the inclusion of unauthorized inputs, verifying data received from users, and preventing the execution of malicious commands through the interface, which reduces the chances of attacks on the application. Our tools are characterized by:",
                arb:"نوفر مجموعة من الأدوات التي تساعد المبرمجين والشركات على إنشاء واجهات مستخدمين عصرية وجذابة من دون الحاجة الى كتابة الكثير من الأسطر البرمجية ومن دون أن يكون للمبرمج خبرة كبيرة في هذا المجال الهام. تلعب هذه الأدوات دوراً حاسماً في تحسين جودة وفعالية التصميم وتحسين تجربة المستخدمين على كافة أنواع الأجهزة مهما اختلف حجم شاشاتها. ومن أهم ما يميز أدواتنا أنها مصممة لتجنب جميع ثغرات الأمان المتعارف عليها مثل تجنب إدراج المدخلات غير المصرح بها، والتحقق من البيانات الواردة من المستخدمين، ومنع تنفيذ الأوامر الضارة عبر الواجهة مما هذا يقلل من فرص الهجمات على التطبيق. تمتاز أدواتنا بأنها:"
              },
              lst:[
                {
                  eng:"Easy to use and provides a set of distinct templates to attract users, improve their experience, and increase their satisfaction, and dealing with them does not require significant experience in programming.",
                  arb:"سهلة الإستخدام وتوفر مجموعة من القوالب المتميزه لجذب المستخدمين و تحسين تجربتهم وزيادة رضاهم ولا يحتاج لتعامل معها الى خبرة كبيرة في البرمجة"
                },
                {
                  eng:"It helps create consistent designs by maintaining uniformity of the companies’ brand visual identity across various platforms and devices.",
                  arb:"تساعد على إنشاء تصميمات متناسقة من خلال المحافظة على توحيد الهوية البصرية لعلامة الشركات التجارية عبر مختلف المنصات والأجهزة"
                },
                {
                  eng:"Help reduce design errors and ensure that the user interface being designed works properly before releasing it to users.",
                  arb:"المساعدة على تقليل أخطاء التصميم والتأكد من أن جهة المستخدم التي يتم تصميمها تعمل بشكل صحيح قبل إطلاقها للمستخدمين"
                },
                {
                  eng:"It provides a set of usage analyzes and tests that can be conducted in order to improve the performance of applications and make them more efficient and effective.",
                  arb:"توفر مجموعة من تحليلات الإستخدام والإختبارات التي يمكن إجرائها من أجل تحسين أداء التطبيقات وجعلها أكثر كفاءة وفاعلية"
                },
                {
                  eng:"It makes it easier for programmers and programming companies to design user interfaces with guaranteed results and do not require maintenance in the future, which reduces future maintenance costs.",
                  arb:"تسهل على المبرمجين وشركات البرمجة تصميم واجهات مستخدمين مضمونة النتائج ولا تحتاج الى صيانة في المستقبل مما يقلل تكاليف الصيانة المستقبلية"
                },
               ]
            },
            {
              name :{eng:"Consulting  Services",arb:"خدمات إستشارية"},
              describe:{
                eng:"By separating our consultants who hold doctoral degrees in various technical fields and whose experience exceeds 20 years in the United States, we are pleased to provide a group of consultations in the following areas:",
                arb:"بفصل مستشارينا الحاصلين على شهادات دكتوراه في مجالات التقنية المختلفة والتي تزيد خبراتهم عن 20 سنة في الولايات المتحدة يسعدنا أن نقدم مجموعة من الإستشارات في المجالات التالية:"
              },
              lst:[
                {
                  eng:"The difficulty of designing applications stems from multiple challenges facing software developers and user interface designers alike. They must combine creativity with technical knowledge to create excellent digital experiences for users. These challenges include providing an excellent user experience, ensuring security and data protection, app compatibility with various devices and platforms, and meeting changing laws and regulations. In addition, the application must be fast, reliable, and easy to maintain, and this highlights the importance of the role of a capable consultant to facilitate this difficult task. With more than 20 years of experience in the United States of America in managing and designing large government projects and smart applications, we provide all types of consultations that guarantee the success of your project at the lowest cost and time and using the latest methods and technology used globally.",
                  arb:"صعوبة تصميم التطبيقات تنبع من تحديات متعددة تواجه مطوري البرمجيات ومصممي واجهات المستخدم على حد سواء. يجب عليهم أن يجمعوا بين الإبداع والمعرفة التقنية لإنشاء تجارب رقمية ممتازة للمستخدمين. تشمل هذه التحديات توفير تجربة مستخدم ممتازة، وضمان الأمان وحماية البيانات، توافق التطبيق مع مختلف الأجهزة والمنصات، تلبية القوانين واللوائح المتغيرة. بالإضافة إلى ذلك، يجب أن يكون التطبيق سريعاً وموثوقاً وسهل الصيانة وهنا يبرز أهمية دور الإستشاري المتمكن لتسهيل هذه المهمة الصعبة. بخبرة تزيد عن 20 سنه في الولايات المتحدة الامريكية في إدارة وتصميم المشاريع الحكومية الضخمة والتطبيقات الذكية نحن نقدم جميع أنواع الإستشارات التي تضمن نجاح مشروعك في أقل تكلفه ووقت وبإستخدام أحدث الأساليب و التكنولوجيا المستخدمة عالمياً.",
                },
                {
                  eng:"Preparing distinguished curricula in technology specializations is considered a matter of great importance in the current era. Designing distinct educational curricula in these disciplines gives students the opportunity to acquire the knowledge and skills necessary to understand and use technology effectively. By meeting market needs, encouraging creativity, and supporting innovation, these approaches can contribute to improving individual competitiveness and promoting technological development and innovation in various fields. In addition, these curricula encourage a culture of continuous learning and provide opportunities for individuals to improve their skills and knowledge throughout their lives. Providing distinguished educational curricula in the fields of technology is a vital investment in the future of society and the economy. With more than 15 years of experience in developing curricula for the most prestigious American universities, we provide integrated consultations in the field of curriculum development and obtaining recognitions from most local and international organizations to ensure that the programs meet international standards in terms of quality and performance.",
                  arb:"تجهيز مناهج دراسية متميزة في تخصصات التكنولوجيا يعتبر أمراً ذا أهمية بالغة في العصر الحالي. إذ يتيح تصميم مناهج تعليمية متميزة في هذه التخصصات للطلاب الفرصة لإكتساب المعرفة والمهارات اللازمة لفهم وإستخدام التكنولوجيا بفعالية. ومن خلال تلبية إحتياجات السوق وتشجيع الإبداع ودعم الابتكار، يمكن أن تسهم هذه المناهج في تحسين تنافسية الفرد وتعزيز التطوير التكنولوجي والابتكار في مختلف المجالات. بالإضافة إلى ذلك، تشجع هذه المناهج على ثقافة التعلم المستمر وتوفير الفرص للأفراد لتحسين مهاراتهم ومعرفتهم طوال حياتهم. إن تجهيز مناهج تعليمية متميزة في مجالات التكنولوجيا هو إستثمار حيوي في مستقبل المجتمع والإقتصاد. بخبرة تزيد عن 15 سنوات في تطوير المناهج الدراسية لأعرق الجامعات الأمريكية نقدم إستشارات متكاملة في مجال تطوير المنهاهج الدراسية وتحصيل الإعترافات من أغلب المنظمات المحلية والدولية لضمان أن البرامج تلبي المعايير العالمية من حيث الجودة والأداء.",
                }
              ]
            },
            {
              name:{eng:"Project Management Services",arb:"خدمات إدارة المشاريع"},
              describe:{
                eng:"Managing software projects requires advanced management and technical skills and is a difficult task that includes many challenges that must be dealt with carefully. Among these challenges are the diversity of the tasks of those involved in the system and the management of what is related to them, the difficulty of determining all the requirements of the system due to the diversity of its users, technical complexities and the need for competent specialists, managing resources and the many changes in the system, and the abundance of platforms and tools used to manage the various stages of the system. We have designed a single smart platform that contains everything necessary to manage and complete applications from A to Z, without the need for any other platform or tools. Below are the most important points that distinguish our platform from other project management platforms:",
                arb:"إدارة المشاريع البرمجية تتطلب مهارات إدارية وتقنية متقدمة كما و تعد مهمة صعبة تتضمن العديد من التحديات التي يجب التعامل معها بعناية. من بين هذه التحديات تنوع مهام المعنيين في النظام وإدارة ما يتعلق بهم, صعوبة تحديد جميع متطلبات النظام نظرا لتنوع مستخدميه, التعقيدات التقنية والحاجة الى مختصين أكفاء, إدارة الموارد والتغيرات الكثيره في النظام, كثرة المنصات والأدوات المستخدمة لإدارة مراحل النظام المختلفة.  لقد قمنا في تصميم منصة ذكية واحدة تحتوي على كل ما يلزم لإدارة وإنجاز التطبيقات من اللأف الى الياء ومن دون الحاجة الى أي منصة او أدوات أخرى. وفيما يلي أهم النقاط التي تميز منصتنا عن باقي منصات إدارة المشاريع:",
              },
              lst:[
                {
                  eng:"The platform helps in managing all resources related to the project and allows the possibility of allocating these resources effectively, which contributes to achieving the project objectives at a lower cost and time.",
                  arb:"تساعد المنصة في إدارة جميع الموارد المتعلقة في المشروع وتتيح إمكانية تخصيص هذه الموارد بفعالية مما يسهم في تحقيق أهداف المشروع بكلفة ووقت أقل. "
                },
                {
                  eng:"The platform undertakes the process of fully coordinating all related tasks at each stage of the application development life cycle. It also provides a central and secure way to store all information related to the project.",
                  arb:"تتولى المنصة عملية التنسيق التام بين جميع المهام المرتبطة في كل مرحلة من مراحل دورة حياة تطوير التطبيق. كما تتيح وسيلة مركزية و آمنة لتخزين جميع المعلومات المتعلقة بالمشروع."
                },
                {
                  eng:"The platform provides all the services required to design complex government applications, which accelerates the digital transformation process at all levels of the country.",
                  arb:"توفر المنصة جميع الخدمات المطلوبة لتصميم تطبيقات حكومية معقدة مما يسرع عملية التحول الرقمي على كافة مستويات الدولة."
                },
                {
                  eng:"The platform supports the design of applications in several languages, including Arabic. The platform adopts secure programming in order to protect applications from the most common attacks in the world of cybersecurity.",
                  arb:"تدعم المنصة تصميم تطبيقات بعدة لغات بما فيها اللغة العربية وتتبنى المنصة البرمجة الآمنة من أجل حماية التطبيقات من أكثر الهجمات شيوعا في عالم الأمن السيبراني."
                },
                {
                  eng:"The platform guarantees the security of the code and designs of the application by not sharing the code with all programmers, which makes it completely suitable for designing government applications or applications related to entrepreneurial ideas.",
                  arb:"تضمن المنصة أمان الكود والتصاميم الخاصة بالتطبيق من خلال عدم مشاركة الكود مع جميع المبرمجين مما يجعلها مناسبة تماما لتصميم تطبيقات حكومية او تطبيقات متعلقة بأفكار ريادية"
                },
               ]
            },
            {
              name:{eng:"Full Stack Training",arb:"تدريب البرمجة المتكامل"},
              describe:{
                eng:"Our training is characterized by adopting the latest programming technologies (Progressive Web Applications - PWA). Our comprehensive training provides a golden opportunity for the programmer to learn everything he needs to build the different parts of the application. The PWA was announced only several years ago, and because it uses JavaScript, which is the most famous programming language, to build a single application that runs on any browser or any mobile device without relying on the Google Store or the Apple Store. It has been widely adopted by private companies in general and governments in particular because it enables them to build mobile applications and supervise their management directly, which means more privacy for users and complete protection of their data. The importance of our comprehensive course is summarized in the following:",
                arb:"يمتاز تدريبنا بتبني أحدث تقنيات البرمجية (التطبيق التقدمي) التي سوف تغني المبرمج عن تعلم أي لغة برمجة أخرى. يقدم تدريبنا المتكامل فرصة ذهبية للمبرمج لكي يتعلم كل ما يحتاجه لبناء أجزاء التطبيق المختلفة.  لقد تم الإعلان عن التطبيق التقدمي قبل عدة سنوات فقط, وبسبب إتستخدمها الجافاسيكربت والتي تعد اشهر لغة برمجه لبناء تطبيق واحد بعمل على اي متصفح او أي جهاز محمول من دون الإعتماد على متجر جوجل او متجر أبل. لقد تم تبنيها بشكل كبير من قبل الشركات الخاصة بشكل عام ومن و الحكومات بشكل خاص لأنها تمكنهم من بناء تطبيقات الموبايل والإشراف على إدارتها بشكل مباشر مما يعني خصوصية أكثر للمستخدمين وحماية كاملة لبياناتهم. تتلخص أهمية دورتنا الشاملة بما يلي:"
              },
              lst:[
                {eng:"Teaching the programmer everything he needs in order to build one secure application that works on all browsers and mobile devices, regardless of their type",arb:"تعليم المبرمج كل ما يحتاجه من أجل بناء تطبيق واحد آمن يعمل على جميع المتصفحات وأجهزة الموبايل مهما اختلف نوعها"},
                {eng:"Focus on the basics of software engineering, such as identifying system users, building applications based on their needs, and ensuring that the application works well in proportion to their needs.",arb:"التركيز على أساسيات هندسة البرمجيات مثل تحديد مستخدمين النظام وبناء تطبيقات بناء على حاجاتهم والتاكد من أن التطبيق يعمل جيدا بما يتناسب مع حاجاتهم"},
                {eng:"Explaining the importance of programming for working in various technology fields such as (artificial intelligence, cybersecurity, Internet of Things, data science) and preparing them to work in it",arb:"توضيح أهمية البرمجة للعمل في مجالات التكنولوجيا المختلفة مثل (الذكاء الإصطناعي, الأمن السيبراني, إنترنت الأشياء, علم البيانات) وتجهيزهم للعمل فيها"},
                {eng:"Empowering the programmer with all the skills required to support entrepreneurship and transform ideas into profitable applications",arb:"تمكين المبرمج من جميع لمهارات المطلوبة لدعم ريادة الأعمال وتحويل الأفكار الى تطبيقات ربحية"},
                {eng:"The programmer will have priority to work with us on one of our huge projects that aims to build modern technologies and libraries used in developing secure software",arb:"سيكون للمبرمج أولوية للعمل معنا على إحدى مشاريعنا الضخمة التي تهدف إلى بناء تقنيات حديثة ومكتبات تستخدم في تطوير البرمجيات الآمنة"},
              ]
            },
            {
              name:{eng:"Secure Server Toolbox",arb:"أدوات حماية السيرفر"},
              describe:{
                eng:"The server is an essential part of any application that is built, regardless of the type of this application (mobile application, website, game applications). This part of the application acts as an intermediary between the database and the various user interfaces, and it involves: managing the data and ensuring its security, writing code to perform complex functions on this data and generating reports from it, performing complex calculations, creating dynamic content and sending it to users’ devices, sending notifications and emails, ensuring Identifying the users and specifying the data they are allowed to view, and finally processing the files received from the users and displaying them when needed. Given the importance of this part and the need to repeatedly use it when building any application, we have:",
                arb:"يعد السيرفر جزءاً أساسياً من أي تطبيق يتم بناءه وبغض النظر عن نوع هذا التطبيق (تطبيق موبايل, ويبسايت, تطبيقات الألعاب). يعمل هذا الجزء من التطبيق كوسيط مابين قاعدة البيانات وواجهات المستخدمين المختلفة ويتم فيه: إدارة البيانات وضمان أمانها, كتابة الكود لتنفيذ الوظائف المعقدة على هذه البيانات وتوليد تقارير منها, إجراء الحسابات المعقدة, إنشاء محتوى ديناميكي وإرساله إلى أجهزت المستخدمين, إرسال الإشعارات والإيميلات, التأكد من هوية المستخدمين وتحديد البيانات المسموح لهم الإطلاع عليها, واخيراً معالجة الملفات المستلمة من المستخدمين وعرضها عند الحاجه اليها. نظرا لأهية هذا الجزء والحاجه لتكرار إستخدامه عند بناء أي تطبيق فقد قمنا في:"
              },
              lst:[
                {eng:"Building a dynamic server capable of communicating with any database and working with any application without the need to change any code",arb:"بناء سيرفر ديناميكي قادر على التواصل مع أي قاعدة بيانات والعمل مع أي تطبيق من دون الحاجة الى تغيير أي كود"},
                {eng:"The server has all the basic features that any application needs, such as verifying users, maintaining data privacy, and sending notifications and e-mail to users.",arb:"يتمتع السيرفر في جميع الميزات الأساسية التي يحتاجها أي تطبيق مثل التحقق من المستخدمين والحفاظ على خصوصية البيانات وإرسال الإشعارات والبريد الإلكتروني للمستخدمين"},
                {eng:"The server protects the application from the most famous cybersecurity attacks and from attacks carried out through various hacking tools. The server has the ability to report any cyber-attack if it occurs",arb:"يحمي السيرفر التطبيق من أشهر هجمات الأمن السيبراني ومن الهجمات التي يتم تنفيذها من خلال أدوات الهكر المتنوعه. ويتمتع السيرفر في إمكانية التبليغ عن أي هجوم سيبراني حال حدوثه "},
                {eng:"Providing a proxy code and the possibility of linking it to the server through simple changes in the code, which helps in distributing and managing requests received from users.",arb:"توفير كود بروكسي وإمكانية ربطه مع السيرفر من خلال تغييرات بسيطة في الكود مما يساعد في توزيع الطلبات المستلمة من المستخدمين وإدارتها"},
                {eng:"The ability to easily modify the server code if the programmer wants to write his own code to perform some exceptional operations on the server",arb:"إمكانية التعديل بسهولة على كود السيرفر في حال أراد المبرمج كتابة كوده الخاص لتنفيذ بعض العمليات الإستثنائية على السيرفر "}
               ]
            },
            {
              name:{eng:"Education Services",arb:"خدمات تعليمية"},
              describe:{
                eng:"Training platforms that collect information about trainees play a crucial role in improving learning processes and developing skills effectively. With a deep understanding of the individual’s needs and response to educational content, curricula and educational materials can be better tailored to suit the individual needs of the trainees, which increases the effectiveness of learning and contributes to enhancing the trainees’ motivation and improving their performance. In addition, this information can be used to provide periodic reports and evaluations that help trainees track their progress and better adjust their educational goals. What distinguishes our platform from other training platforms is the following:",
                arb:"منصات التدريب التي تجمع معلومات عن المتدربين تلعب دوراً حاسماً في تحسين عمليات التعليم وتطوير المهارات بشكل فعّال. بفهم عميق لإحتياجات الفرد وإستجابته للمحتوى التعليمي يمكن تخصيص المناهج والمواد التعليمية بشكل أفضل لتناسب احتياجات المتدربين الفردية مما يزيد من فعالية التعلم ويسهم في تعزيز تحفيز المتدربين والارتقاء بأدائهم. بالإضافة إلى ذلك، يمكن إستخدام هذه المعلومات لتقديم تقارير وتقييمات دورية تساعد المتدربين على تتبع تقدمهم وضبط أهدافهم التعليمية بشكل أفضل. ما يميز منصتنا عن غيرها من منصات التدريب ما يلي:",
              },
              lst:[
                {
                  eng:"The training platform that we designed includes everything that any training center or private trainer needs in order to provide any type of courses.",
                  arb:"تشمل المنصة التدريبية التي صممناها على كل ما يحتاجه أي مركز تدريب او مدرب خاص من أجل تقديم أي نوع من الدورات."
                },
                {
                  eng:"What distinguishes our platform is that it supports any language and is built to facilitate the support of all required competencies according to specialization, and most of the evaluation processes for trainees and trainers are carried out automatically.",
                  arb:"ما يميز منصتنا انها تدعم أي لغة ومبنية لتسهل دعم جميع الكفاءات المطلوبة على حسب التخصص ويتم فيها إغلب عمليات التقييم للمتدربين وللمدربين بشكل تلقائي."
                },
                {
                  eng:"The training platform guarantees the protection of training content for trainers, such as explanation videos and all types of files linked to the training content.",
                  arb:"تضمن المنصة التدريبية حماية المحتوى التدريبي للمدربين مثل فيديوهات الشروحات وكافة أنواع الملفات المرتبطة في المحتوى التدريبي."
                },
                {
                  eng:"It also contains an integrated examination system to evaluate the level of students and helps provide communication channels between trainers and trainees.",
                  arb:"كما تحتوي على نظام إمتحانات متكامل لتقييم مستوى الطلبة وتساعد على توفير قنوات تواصل بين المدربين والمتدربين. "
                },
                {
                  eng:"It allows platform administrators to collect fees from trainees, print certificates, evaluate the performance of trainers, and manage them.",
                  arb:"تتيح للمسؤولين عن المنصة إمكانية تحصيل الرسوم من المتدربين وطباعة الشهادات وتقييم أداء المدربين وإدارتهم."
                },
               ]
            }
          ]
        },
        support:{
          title:{arb:"الصناعات التي ندعمها",eng:"Industries We Support"},
          subtitle:{arb:"",eng:""},
          lst:[
            {
              heading:{arb:"خدمات التجزئة",eng:"Retail Services"},
              describe:{arb:"يمكن استخدام أدوات وتقنيات أعمال البيع بالتجزئة لدينا بطرق مختلفة: لزيادة راحة المتسوقين، أو لتعزيز تواجد بائع التجزئة عبر الإنترنت، أو لجذب المستخدمين.",eng:"Our retail business tools and technology can be used in various ways: to increase the convenience for shoppers, to promote a retailer’s online presence, or to appeal to users."},
            },
            {
              heading:{arb:"السفر والنقل",eng:"Travel & Transportation"},
              describe:{arb:"ومع تزايد المنافسة في قطاع النقل، فإننا نساعد عملائنا على زيادة الكفاءة التشغيلية من خلال تكنولوجيا السفر المتطورة.",eng:"With growing competition in the transportation sector, we help our customers to increase operational efficiency with cutting-edge travel technology."},
            },
            {
              heading:{arb:"الصناعة التحويلية",eng:"Manufacturing Industry"},
              describe:{arb:"يمكن للصناعات التحويلية استخدام تكنولوجيا التصنيع المبتكرة والأدوات المتقدمة لدينا لتحقيق أقصى قدر من جودة المنتج وتقليل تكاليف الإنتاج.",eng:"Our innovative manufacturing technology and advanced tools can be used by manufacturing industries to maximize product quality and reduce production costs."},
            },
            {
              heading:{arb:"الخدمات المالية",eng:"Financial Services"},
              describe:{arb:"يمكن للمؤسسات المالية الاستفادة من تقنياتنا لتقليل ساعات العمل، وتحسين جودة العلاقات مع العملاء، وتحسين الربحية.",eng:"Financial institutions can benefit from our technologies to reduce man-hours, improve the quality of customer relationships, and improve profitability."},
            },
            {
              heading:{arb:"العمودي الناشئة",eng:"Emereging Verticals"},
              describe:{arb:"نحن نعتزم تجاوز مكانتنا كمزود رائد لخدمات البرمجيات من خلال تحليل وبحث التحولات والانفجارات في القطاعات التقليدية.",eng:"We intend to transcend our status as a leading Software Service provider by analyzing and researching the shifts and flare-ups in conventional verticals."},
            },
            {
              heading:{arb:"صناعة التعليم",eng:"Education Industry"},
              describe:{arb:"بناء منصات تعليمية إلكترونية متقدمة تعتمد على النظرية التعليمية لمساعدة الجامعات والمدارس على تسهيل التعلم ومنح الطلاب تجارب تعليمية مذهلة.",eng:"Building advanced e-learning platforms based on educational theory to help universities and schools facilitate learning and give students amazing educational experiences."},
            },
          ]
        },
        contactus:{
          title:{eng:"Contact us", arb: "تواصل معنا"},
          consultation:{eng:"Consultation", arb:"إستشارة"},
          development:{eng:"Development", arb:"تطوير"}
        },

        header:{
          websiteName:{arb:"CBM Integrated Software Inc.",eng:"CBM Integrated Software Inc."},
          websiteTitle:{arb:"حيث تلتقي الاستخبارات بالدفاع السيبراني",eng:"Where Intelligence Meets Cyber Defense"},

          "home":{eng:"Home",arb:"الرئيسية"},
          "whyChoose":{arb:"لماذا تختار",eng:"Why CBMIS"},
          "lifecycle":{arb:"دورة حياة تطوير البرمجيات لدينا",eng:"Our SDLC"},
          "vision":{arb:"قيمنا",eng:"Our Mission"},
          "services":{arb:"خدماتنا",eng:"Services"},
            training :{eng:"Training Services",arb:"التدريب"},
            fullStackTraining :{eng:"Full Stack Training",arb:"تدريب البرمجة المتكامل"},
            cyberSecurity:{eng:"Cybersecurity Solution",arb:"الأمن السيبراني"},
            cyberSecurityServer:{eng:"Secure Server Toolbox",arb:"أدوات حماية السيرفر"},
            softwareDevelopment :{eng:"Software Development",arb:"تصميم التطبيقات"},
            educationalServices :{eng:"Education Services",arb:"خدمات تعليمية"},
            projectManagement:{eng:"Project Management",arb:"إدارة المشاريع"},
            consultationServices:{eng:"Consulting  Services",arb:"خدمات إستشارية"},
          "support":{arb:"الصناعات",eng:"Industries"},
            retail:{arb:"خدمات التجزئة",eng:"Retail Services"},
            travel:{arb:"السفر والنقل",eng:"Travel & Transportation"},
            manufacturing:{arb:"الصناعة التحويلية",eng:"Manufacturing Industry"},
            financial:{arb:"الخدمات المالية",eng:"Financial Services"},
            emereging:{arb:"العمودي الناشئة",eng:"Emereging Verticals"},
            education:{arb:"صناعة التعليم",eng:"Education Industry"},
            thingsYouGet:{arb:"الفوائد",eng:"Benefits"},

            contactus:{eng:"Contact us",arb:"تواصل معنا"},

          copyRight:{eng:"@ 2024 CBM Integrated Software Inc. All Right Reserved", arb:"2024 CBM Integrated Software Inc. All Right Reserved @"}
        }
      }
    },
    account: {
      CardMember: { eng: "Membership Card", arb: "بطاقة المستخدمية" },
      logout: { eng: "Logout", arb: "تسجيل الخروج" },
      dtmEnd: { eng: "Subscriber until", arb: "مشترك لغاية" },
      member: { eng: "Member", arb: "عضو" },
      employee: { eng: "Employee", arb: "موظف" },
      founder: { arb: "إداري", eng: "Maneger" },
      services: { eng: "Services", arb: "خدمات" },
      announcement: { eng: "Announcement", arb: "إعلان" },
      training: { eng: "Training", arb: "تدريب" },
      liveSession: { eng: "Live Session", arb: "بث مباشر" },
      liveSessionAvailable: { eng: "Tutorial will be available", arb: "سيكون الدرس التعليمي متاح" },
      liveSessionCurrentLive: { eng: "Current Tutorial", arb: "الدرس التعليمي الحالي" },
      newLiveSessionAvailable: { eng: "Tutorial", arb: "درس تعليمي" },
      joinBtn: { eng: "Join the live broadcast", arb: "إنضم للبث المباشر" },
    },
    contact: {
      mngTitle: { eng: "Emails", arb: "البريد الإلكتروني" },
      title: { eng: "View Email", arb: "عرض البريد الوارد" },
      inbox: { eng: "Inbox", arb: "البريد الوارد" },
    },
    supscrption: {
      title: { eng: "Newsletter Members", arb: "النشرة الإخبارية" },
      numSubscriper: { eng: "Number of subscribers", arb: "عدد المشتركين" },
    },
    mnageRequestJoin: {
      title: { eng: "Manage Trainee", arb: "إدارة المتدربين"},
      inProcess: { eng: "In process", arb: "قيد المعالجة" },
      notProcessed: { eng: "Not processed", arb: "لم تتم معالجتها" },
      newRequested: { eng: "New Requests", arb: "الطلبات جديدة" },
      RequestsNotActions: { eng: "Requests not taken actions", arb: "لم يتم اتخاذ الإجراءات للطلبات" },

      vewtitle: { eng: "View Requested", arb: "عرض طلب الإنضمام" },
      requirementTitle: { eng: "Subscriptions Renewal", arb: "تجديد الإشتراكات" },

      requiremenFiles: { eng: "Required Files", arb: "الملفات المطلوبة" },
      approvedUser: { eng: "Approved", arb: "الموافق عليها" },
      excludedUser: { eng: "Denied", arb: "المرفوضة" },
      pendingUser: { eng: "New", arb: "الجديدة" },
      changeUserStatusConfirme: { eng: "Are you sure you want to change user status ?", arb: "هل أنت متأكد تغيير حالة المستخدم؟" },
      userStatusUpdateSuccess: { eng: "User status updated successfully", arb: "تم تحديث حالة المستخدم بنجاح" },
      userInfoUpdateSuccess: { eng: "User information updated successfully", arb: "تم تحديث معلومات المستخدم بنجاح" },
      update: { eng: "Update", arb: "تحديث" },
      approve: { eng: "Approve", arb: "موافقة" },
      exclude: { eng: "Deny", arb: "رفض" },
      exception: { eng: "Exception", arb: "استثناء" },
      student: { eng: "Student", arb: "طالب" },
      entrepreneur: { eng: "Entrepreneur", arb: "ريادي" },
      manager: { eng: "Manager", arb: "مدير" },
      note: { eng: "Please record all information before confirming the order or request", arb: "الرجاء التأكد من جميع المعلومات قبل تأكيد أو رفض الطلب" },
      noteApproved: { eng: `This trainee will be excluded from training when clicked “Exception”`, arb: `سيتم استثناء هذا المتدرب من التدريب في حال النقر على "استثناء"`},
      notExclude: { eng: `This trainee will be allowed to enter the training when clicking on “Approve”`, arb: `سيُسمح لهذا المتدرب بالدخول للتدريب عند النقر على "موافقة"`},
      filterBtn :{ eng: "Filter", arb:"فلتر"}
    },
    mngAnnouncement: {
      title: { eng: "Manage Anncouncments", arb: "إدارة الإعلانات" },
      titleLiveSession: { eng: "Manage Live Broadcast", arb: "إدارة البث المباشر" },
      confirmation: { deleteAnnouncement: { eng: "شre sure of the deleting process?", arb: "هل أنت متأكد من عملية الحذف؟"} },
      label: {
        announcement: { eng: "Announcement", arb: "الإعلان" },
        isMeetingAnnouncement: { eng: "Type", arb: "النوع" },
        noAnnouncement: { eng: "No Announcements Yet", arb: "لا توجد إعلانات حتى الآن" },
        addAnnouncement: { eng: "Add", arb: "إضافة" },
        updateAnnouncement: { eng: "Update", arb: "تعديل" },
        title: { eng: "Title", arb: "العنوان" },
        link: { eng: "Meeting Link", arb: "رابط الإجتماع" },
        body: { eng: "Body", arb: "المحتوى" },
        from: { eng: "From", arb: "من" },
        to: { eng: "To", arb: "إلى" },
        noMeeting: { eng: "No broadcast yet", arb: "لا يوجد بث مباشر" },
        announcementInfo: { eng: "Information", arb: "معلومات" },
        copyMeetingLink: { eng: "Copy Meeting Link", arb: "انسخ رابط الاجتماع" },
        targetGroup: { eng: "Target Group", arb: "الفئة المستهدفة" },
        uploadAttachFiles: { eng: "Upload Attachment Files", arb: "تحميل الملفات المرفقة" },
        liveSessionAvailable: { eng: "Live session will be available", arb: "سيكون الإجتماع متاح" },
        liveSessionCurrentLive: { eng: "Current Live session", arb: "الإجتماع الحالي" },
        newLiveSessionAvailable: { eng: "Live session", arb: "بث مباشر" },
        newAnnouncement: { eng: "Announcement", arb: "إعلان" },
      },
    },
    managePartner: {
      title: { eng: "Manage Partner", arb: "إدارة الجهات الداعمة" },
      inProcess: { eng: "In process", arb: "قيد المعالجة" },
      notProcessed: { eng: "Not processed", arb: "لم تتم معالجتها" },
      newRequested: { eng: "New Requests", arb: "الطلبات جديدة" },
      RequestsNotActions: { eng: "Requests not taken actions", arb: "لم يتم اتخاذ الإجراءات للطلبات" },

      vewtitle: { eng: "View Requested", arb: "عرض طلب الإنضمام" },
      requirementTitle: { eng: "Subscriptions Renewal", arb: "تجديد الإشتراكات" },

      requiremenFiles: { eng: "Required Files", arb: "الملفات المطلوبة" },
      noHomeLogo: { eng: "The sponsor has been confirmed but does not want to appear on the home page", arb: "لقد تم التأكد من الجهة الداعمة، ولكن شعارها لا يُعرض في الصفحة الرئيسية " },
      noLogo: {
        eng: "The supporting party has been confirmed and wants to appear on the home page, but its logo has not been uploaded.",
        arb: "لقد تم التأكيد الجهة الداعمة و تريد الظهور في الصفحة الرئيسية ولكن لم يتم رفع شعارها",
      },
      homeLogo: { eng: "The supporting party has been confirmed and its logo has been displayed on the home page.", arb: "لقد تم التأكيد الجهة الداعمة وتم عرض شعارها في الصفحة الرئيسية" },
    },
    manageSystem: {
      title: { eng: "Manage System", arb: "إدارة النطام" },
      requirementTitle: { eng: "Subscriptions Renewal", arb: "تجديد الإشتراكات" },
      noRequirementInfo: { eng: "No additional information is required", arb: "لا يشترط أي معلومات إضافية" },
      requirementInfoDuringReg: { eng: "Requirement Information During Registration", arb: "المعلومات المطلوبة أثناء التسجيل" },
      requirementInfoAfterReg: { eng: "Requirement Information After Registration", arb: "المعلومات المطلوبة بعد التسجيل" },

      strNote: { eng: "Note", arb: "ملاحظة" },
      strAccountBank: { eng: "Account Bank", arb: "الحساب المصرفي" },
      strAmount: { eng: "Amount required", arb: "المبلغ المطلوب" },
      govID: { eng: "Government ID", arb: "الهوية الشخصية" },
      rest: { eng: "Bank receipt", arb: "الوصل البنكي" },

      strViewAccountBank: { eng: "View Account Bank", arb: "عرض الحساب المصرفي" },

      currency: { eng: "KWD", arb: "د.ك" },
      month: { eng: "Month", arb: "شهر" },
      day: { eng: "Day", arb: "يوم" },

      uploadedRequirementInfo: { eng: "Please upload the following required files or image:", arb: "يرجى تحميل الملفات أو الصورة المطلوبة التالية:" },

      noteType: { eng: "If you would like to send the applicant a note, please enter it in both Arabic and English.", arb: "إذا أردت إرسال للمتقدم ملاحظة فيرجى إدخالها باللغتين العربية والإنجليزية" },

      subscriptionInfo: { eng: "Subscription Information", arb: "معلومات الإشتراك" },
    },
    manageMembers: {
      title: { eng: "Manage Members", arb: "إدارة الأعضاء" },
      search: { eng: "Search by name or phone number", arb: "البحث عن طريق الإسم أو رقم الهاتف" },
      vewtitle: { eng: "Member Information", arb: "معلومات المستخدم" },
      resetPassword: { eng: "Reset Password", arb: "تغيير كلمة المرور" },
      resetEmail: { eng: "Reset Email", arb: "تغيير البريد الإلكتروني" },
      resetPhone: { eng: "Reset Phone", arb: "تغيير رقم الهاتف" },

      profile: { eng: "Profile", arb: "المعلومات الشخصية" },
      subscription: { eng: "Subscription valid for date", arb: "الاشتراك صالح لتاريخ" },

      cardMember: { eng: "Card Member", arb: "بطاقة المستخدمية" },
      personalInfo: { eng: "Personal Information", arb: "المعلومات الشخصية" },
      sensitiveInfo:{ eng: "You can update your sensitive information, such as email, phone number, and password, simply by clicking on the dedicated button", arb:"يُمكنك تحديث معلوماتك الحساسة، مثل البريد الإلكتروني ورقم الهاتف وكلمة المرور، ببساطة عبر النقر على الزر المخصص لها"}
    },
    manageManagers: {
      title: { eng: "Managers Training Department", arb: "إدارة تدريب المدراء" },
      search: { eng: "Search by name or phone number", arb: "البحث عن طريق الإسم أو رقم الهاتف" },
      vewtitle: { eng: "Member Information", arb: "معلومات المستخدم" },
      resetPassword: { eng: "Rest Password", arb: "تغيير كلمة المرور" },
      resetEmail: { eng: "Rest Email", arb: "تغيير البريد الإلكتروني" },

      profile: { eng: "Profile", arb: "المعلومات الشخصية" },
      subscription: { eng: "Subscription valid for date", arb: "الاشتراك صالح لتاريخ" },

      cardMember: { eng: "Card Member", arb: "بطاقة المستخدمية" },
    },
    waiting: {
      title: { eng: "Thank you for joining the Artificial Intelligence for Things Association", arb: "نشكرك لانضمامك لجمعية الذكاء الإصطناعي للأشياء" },
      titleTraining: { eng: "Thank you for joining the symposium of Artificial Intelligence for Things Association", arb: "نشكرك لانضمامك لندوة جمعية الذكاء الإصطناعي للأشياء" },
      subTitle: { eng: "We are now processing your request", arb: "نعمل الآن على معالجة طلبك" },
      waite: { eng: "Please Wait", arb: "يرجى الإنتظار" },
      btn: { eng: "Go to Home page", arb: "العودة إلى الصفحة الرئيسية" },
    },
    joinSymposium: {
      titleJoin: { eng: "Joining a seminar.", arb: "الإنضمام لندوة" },
      btnReg: { eng: "Registration", arb: "انشاء حساب" },
      thank: {
        eng: "Thank you for your interest in joining the scientific seminar for the development of the technology sector in Kuwait.",
        arb: "شكرًا لك على اهتمامك بالإنضمام لندوة العلمية لتطوير قطاع التكنولوجيا في الكويت",
      },
      academic: { arb: "قطاع أكاديمي", eng: "Academic sector" },
      gov: { arb: "قطاع حكومي", eng: "Government sector" },
      prvt: { arb: "قطاع خاص", eng: "Private sector" },
    },
  },
  formapi: {
    strFullName: { label: { eng: "Name", arb: "الإسم" } },
    bigCountryID: { label: { eng: "Country", arb: "البلد" } },
    bigDegreeID: { label: { eng: "Degree", arb: "الدرجة العلمية" } },
    strUserEmail: { label: { eng: "Email", arb: "البريد الإلكتروني" } },
    bigSubjectID: { label: { eng: "Subject", arb: "الموضوع" } },
    strMessage: { label: { eng: "Enter your message", arb: "الرسالة" } },
    strUserPhoneNumber: { label: { eng: "Phone Number", arb: "رقم الهاتف" } },
    strSoftwareNeededOn: { label: { eng: "Software Neede on", arb: "رقم الهاتف" } },
    strVerificationCode: { label: { eng: "Verification Code", arb: "رمز التحقق" } },

    bigPurposeID: {
      label: { eng: "Reason for communication", arb: "سبب التواصل" },
      options: {
        complaint: { eng: "Complaint", arb: "شكوى" },
        inquiry: { eng: "Inquiry", arb: "استفسار" },
        suggestion: { eng: "Suggestion", arb: "اقتراح" },
      },
    },
    trainingNumberAdmin: { label: { eng: "Training Number", arb: "عدد المتدربين المراد دعمهم" } },

    dtmDob: { label: { eng: "Date of Birthday", arb: "تاريخ الميلاد" } },
    age: { label: { eng: "Age", arb: "العمر" } },

    strPhone: {
      label: { eng: "Phone", arb: "رقم الهاتف" },
      validationsMsg: {
        maxLength: { eng: "The number of fields allowed to be entered is 8", arb: "عدد الخانات المسموح بإدخالها هي 8" },
        minLength: { eng: "The number of fields allowed to be entered is 8", arb: "عدد الخانات المسموح بإدخالها هي 8" },
        onlyNumber: { eng: "Only numbers are allowed", arb: "مسموح  فقط أرقام" },
      },
    },
    bigGenderID: {
      label: { eng: "Gender", arb: "الجنس" },
    },
    bigEmploymentID: { label: { eng: "Employment Status", arb: "الحالة الوظيفية" } },
    bigMajorID: { label: { eng: "Major", arb: "التخصص" } },
    strMajor: { label: { eng: "Major", arb: "التخصص" } },

    dtmCreatedDate: { label: { eng: "Registration Date", arb: "تاريخ التسجيل" } },

    bigGender: { label: { eng: "Gender", arb: "الجنس" } },
    bigNationality: { label: { eng: "Nationality", arb: "الجنسية" } },

    strEmail: { label: { eng: "Email", arb: "البريد الإلكتروني" } },
    strComment: { label: { eng: "Enter your comment", arb: "تعليقك" } },

    strRestEmail: { label: { eng: "New Email", arb: "البريد الإلكتروني الجديد" } },
    strRestPassword: { label: { eng: "New Password", arb: "كلمة المرور الجديدة" } },

    jsnSystemConfig: { label: { eng: "System Name", arb: "System Name" } },
    strPathUrl: { label: { eng: "Path Url", arb: "Path Url" } },
    bigOrganizationType: { label: { eng: "Organization Type", arb: "Organization Type" } },
    blnHaveTargetedPeople: { options: { eng: "Email", arb: "البريد الإلكتروني" } },

    strPositionTitle: { label: { eng: "Position title", arb: "المسمى الوظيفي" } },
    bigWorkSector: { label: { eng: "The work sector", arb: "نوع القطاع" } },
    strWorkPlace: { label: { eng: "Name of place of work or study", arb: "مكان العمل أو الدراسة" } },

    strWorkGovPlace: { label: { eng: "The workplace name", arb: "مكان العمل" } },

    strWorksPlace: { label: { eng: "The workplace name", arb: "مكان العمل" } },
    strPlaceName: { label: { eng: "Sponsor Name", arb: "اسم الجهة الداعمة" } },
    strURL: { label: { eng: "Website Link", arb: "رابط الموقع" } },
    intAge: { label: { eng: "Age", arb: "العمر" } },

    strUserPhone: { label: { eng: "Phon Number", arb: "رقم الهاتف" } },
    strVideoLink: { label: { eng: "Video Link", arb: "رابط الفيديو" } },
    strOldUserPassword: { label: { eng: "Old Password", arb: "كلمة المرور القديمة" } },
    strUserPassword: {
      label: { eng: "Password", arb: "كلمة المرور" },
      validationsMsg: {
        equal: { eng: "password does not match", arb: "كلمة المرور غير متطابقة" },
        minLength: { eng: "Input less than 4 digits", arb: "عدد الخانات التي تم إدخالها أقل من 4 خانات" },
      },
    },
    strConfirmPassword: {
      label: { eng: "Confirm Password", arb: "تأكيد كلمة المرور" },
      validationsMsg: {
        equal: { eng: "password does not match", arb: "كلمة المرور غير متطابقة" },
        minLength: { eng: "The number of digits entered is less than the permissible limit of 4 digits", arb: "عدد الخانات التي تم إدخالها أقل من الحد المسموح به هو  4خانات" },
      },
    },
    strFullNameSearch: { placeholder: { eng: "Search by name", arb:"البحث بالإسم"}},

    intGPA: { label: { eng: "GPA", arb: "المعدل التراكمي" } },
    strUniversityName: { label: { eng: "University", arb: "الجامعة" } },
    bigCountryOfCitizenID: { label: { eng: "Country Of Citizen", arb: "بلد الجنسية" } },
    bigCountryOfResidenceID: { label: { eng: "Country Of Residence", arb: "بلد الإقامة" } },
    bigAcademicYearID: { label: { eng: "Academic Year", arb: "السنة الأكاديمية" } },
    dtmGradDate: { label: { eng: "Graduation Date", arb: "تاريخ التخرج" } },
    strAddress: { label: { eng: "Address", arb: "العنوان" } },
    strGovID: { label: { eng: "Government ID", arb: "الهوية الحكومية" } },
    bigGpaID: { label: { eng: "GPA", arb: "المعدل التراكمي" } },
    bigFlowID: { label: { eng: "Registration flow with requirements information", arb: "خطوات التسجيل مع متطلبات التسجيل" } },
    listContent: { label: { eng: "Content Information Requirements", arb: "محتوى  متطلبات التسجيل" } },
    strNote: { label: { eng: "Note", arb: "ملاحظة" } },
    strAccountBank: { label: { eng: "Account Bank", arb: "الحساب المصرفي" } },
    strAmount: { label: { eng: "Amount required", arb: "المبلغ المطلوب" } },
    listRequiredDoc: { label: { eng: "Required document", arb: "الملفات المطلوبة" } },
    strBankName: { label: { eng: "Bank Name", arb: "اسم البنك" } },
    strAccountBankName: { label: { eng: "Account Name", arb: "اسم المستفيد" } },
    strAccountIBAN: { label: { eng: "Account IBAN ID", arb: "رقم حساب الIBAN" } },

    title: { label: { eng: "Title", arb: "العنوان" } },
    linkUrl: { label: { eng: "Link Url", arb: "الرابط" } },
    linkLabel: { label: { eng: "Link Title", arb: "عنوان الرابط" } },
    source: { label: { eng: "Source", arb: "المصدر" } },
    strItemPoint: { label: { eng: "Text", arb: "نص" } },

    blnIsActive: { label: { eng: "Activate member account", arb: "تفعيل حساب المستخدم" } },
    intMonthDuration: { label: { eng: "Subscription Duration", arb: "مدة الإشتراك" } },
    intTimeNotify: { label: { eng: "Notification period when the subscription is about to expire", arb: "مدة التبليغ عن قرب إنتهاء الإشتراك" } },
    blnIsPaySubscribed: { label: { eng: "Activate paid subscription", arb: "تفعيل الإشتراك المدفوع" } },

    //#region manage announcement
    strAnnouncementTitle: { label: { eng: "Announcement Title", arb: "عنوان الإعلان" } },
    strAnnouncementBody: { label: { eng: "Announcement Body", arb: "محتوى الإعلان" } },
    blnIsMeeting: { options: { true: { eng: "Is Live Session", arb: "بث مباشر" } } },
    strMeetingLink: { label: { eng: "Live Session Link", arb: "رابط البث المباشر" } },
    blnSpecificCountry: { options: { true: { eng: "Sending announcement to specific nationalities of users", arb: "إرسال الإعلان للمستخدمين من جنسيات محددة" } } },
    lstCountryID: { label: { eng: "Countries", arb: "البلدان" } },
    blnSpecificUniversity: {
      options: { true: { eng: "Send the announcement to users of specific university graduates", arb: "إرسال الإعلان للمستخدمين من خريجي الجامعات المحددة" } },
    },
    lstUniversityID: { label: { eng: "Universities", arb: "الجامعات" } },
    blnSpecificAcademicYear: {
      options: { true: { eng: "Sending the announcement to users in a specific academic year", arb: "إرسال الإعلان للمستخدمين في سنة أكاديمية محددة" } },
    },
    intAcademicYear: { label: { eng: "Academic Year", arb: "السنة الأكاديمية" } },
    blnWithTimeLimit: { options: { true: { eng: "Show Notification Within Specific Time Range", arb: "إظهار الإعلان ضمن نطاق زمني محدد" } } },
    dtmTo: { label: { eng: "To", arb: "إلى" } },
    dtmFrom: { label: { eng: "From", arb: "من" } },
    blnSpecificRole: { options: { true: { eng: "Sending the announcement to specific user", arb: "إرسال الإعلان لدور محدد للمستخدمين" } } },
    lstUserRole: { label: { eng: "User Role", arb: "دور المستخدم" } },
    strTime: { label: { eng: "Time text", arb: "نص تحديد الوقت" } },
    //#endregion

    //#region file Uploader
    strFileLabel: { label: { eng: "File Title", arb: "عنوان الملف" } },
    intNumberOfFile: { label: { eng: "The Maximum Number Of Files", arb: "الحد الأقصى لعدد الملفات" } },
    strValidationType: { label: { eng: "Files Type", arb: "نوع الملفات" } },

    required: { eng: "Required", arb: "الحقل مطلوب" },
    //#endregion

    strSubject:{label:{eng:"Subject",arb:"الموضوع"}},
    strMsg:{label:{eng:"Enter your message",arb:"الرسالة"}},



    strCompanyName: {label:{eng:"Company Name",arb:"اسم الشركة"}},
    strCompanyWebsite: {label:{eng:"Company Website",arb:"موقع الشركة"}},
    strCompanyEmail: {label:{eng:"Company Email",arb:"البريد الإلكتروني لشركة"}},
    strPhoneNumber:{label:{eng:"Company Phone Number",arb:"رقم تلفون الشركة"}},
    strCompanyAddress: {label:{eng:"Company Address",arb:"عنوان الشركة"}},
    intCompanyEmployeeNum: {label:{eng:"Employee Number",arb:"عدد الموظفيين"}},
    strTypeConsultaionService:{label:{eng:"Type of consultation",arb:"نوع الاستشارة"}},
    strBestToCall:{label:{eng:"Best time to call",arb:"أفضل وقت لإتصال"}},
    strProjectType:{label:{eng:"Project Type",arb:"نوع المشروع"}},
    strBusinessIndustry:{label:{eng:"Business Industry",arb:"قطاع العمل"}},

  },
};
