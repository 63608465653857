import { Alert, Confirmation } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../../appHelper/appFunctions";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import { generateQueries, objTableAttributes } from "../../../../../appHelper/appQuery/GenerateQuery";
import objAppRouting from "../../../../../appHelper/routing/appRouting";
import {  ITC_System_ID } from "../../../../../appHelper/appVariables";

const strPageInfo = "@src/component/public/advertisementSystem/view/contact/controler/CtrlContact.js";

const tblMail = generateQueries("tblMail");
const tblSystem = generateQueries("tblSystem");

export const CtrlContact = (appState, isLoading, setIsLoading, stateInfo, setStateInfo, navigate = null) => {
  const lang = appState?.clientInfo?.strLanguage;
  const bigSystemID = appState?.userInfo?.bigSystemID || ITC_System_ID;

  let handler = {
    getMail: async () => {
      try {
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }

        setIsLoading(true);
        const result = await tblMail(strPageInfo, appState, "aaiotAppFindAll", {
          arrAttributes: objTableAttributes?.tblMail?.full,
          objectCondition: { bigSystemID: bigSystemID, blnIsActive: true, blnIsDeleted: false },
        });

        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cantFindRecords?.[lang], "warning");
          setIsLoading(false);
          return;
        }

        stateInfo.blnIsInitDone = true;
        let lstTmp = Array.isArray(result?.response) ? result?.response : [];

        stateInfo.lstMail = lstTmp.reverse();
        setIsLoading(false);
        setStateInfo({ ...stateInfo });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false);
      }
    },
    viewMail: async (item, appDispatch) => {
      try {
        if (item) {
          if (!item?.blnIsSeen) {
            if (isLoading) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
              return;
            }
            setIsLoading(true);

            const result = await tblMail(strPageInfo, appState, "aaiotAppUpdate", {
              objectInput: { blnIsSeen: true },
              objectCondition: { bigMailID: item?.bigMailID, bigSystemID: bigSystemID },
            });

            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
              setIsLoading(false);
              return;
            }

            const resultRequestedIncCount = await tblSystem(strPageInfo, appState, "itcAppIncDecInt", {
              arrAttributes: ["intCountMail"],
              objectCondition: {
                bigSystemID: appState?.userInfo?.bigSystemID,
                blnIsActive: true,
                blnIsDeleted: false,
                operation: "dec",
              },
            });

            if (!resultRequestedIncCount?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response, "");
              return;
            }

            appState.clientInfo.objSystemInfo.intCountMail = appState?.clientInfo?.objSystemInfo?.intCountMail - 1;

            setIsLoading(false);
            setStateInfo({ ...stateInfo, blnIsInitDone: true });
            appDispatch();
          }
        }
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
        setIsLoading(false);
      }
    },
    checkAll: (e) => {
      stateInfo.lstMailChecked = [];
      if (e.target.checked) {
        stateInfo?.lstMail?.forEach((element) => {
          stateInfo.lstMailChecked.push(element.bigMailID);
        });
      }
      setStateInfo({ ...stateInfo });
    },
    checkMail: (e) => {
      if (e.target.checked) {
        stateInfo.lstMailChecked.push(Number(e.target.id));
      } else {
        stateInfo.lstMailChecked = stateInfo.lstMailChecked?.filter((element) => element !== Number(e.target.id));
      }
      setStateInfo({ ...stateInfo });
    },
    delateAllMailConfirmation: () => {
      const delateMail = async () => {
        try {
          setIsLoading(true);
          stateInfo.lstMailChecked.forEach(async (element) => {
            const result = await tblMail(strPageInfo, appState, "aaiotAppDelete", {
              objectCondition: { bigMailID: element, bigSystemID: bigSystemID },
            });

            if (!result?.blnIsRequestSuccessful) {
              appFunctions.logMessage(result?.response);
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Delete_Record?.[lang], "warning");
              setIsLoading(false);
              return;
            }
          });
          const listNotSeen = stateInfo.lstMail?.filter((item) => stateInfo?.lstMailChecked?.includes(item?.bigMailID) && !item?.blnIsSeen);
          if (listNotSeen.length) {
            if (isLoading) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "info");
              return;
            }
          }
          setIsLoading(false);
          stateInfo.lstMail = stateInfo.lstMail.filter((el) => !stateInfo.lstMailChecked.includes(el.bigMailID));
          stateInfo.lstMailChecked = [];
          setStateInfo({ ...stateInfo });

          setStateInfo({ ...stateInfo });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      };

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.deleteAllMail?.[lang],
        delateMail,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
    delateMailConfirmation: (item) => {
      const delateMail = async () => {
        try {
          setIsLoading(true);
          const result = await tblMail(strPageInfo, appState, "aaiotAppDelete", {
            objectCondition: { bigMailID: item?.bigMailID, bigSystemID: bigSystemID },
          });

          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Delete_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          stateInfo.lstMail = stateInfo.lstMail.filter((mail) => mail.bigMailID !== item.bigMailID);
          setStateInfo({ ...stateInfo });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
          navigate(objAppRouting?.Contact?.url, { replace: true });
        }
      };

      Confirmation.viewConfirmation(
        dictionary?.shared?.confirmationMsg?.deleteMail?.[lang],
        delateMail,
        () => null,
        dictionary?.shared?.confirmationMsg?.yes?.[lang],
        dictionary?.shared?.confirmationMsg?.no?.[lang]
      );
    },
  };

  return handler;
};
