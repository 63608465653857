
export const App_Name = "itcApp";
export const App_ID = 7253851520;

export const cbmisHost = "https://www.cbmis.com/";

//=============================== Must be updated with any new changes ===============================================================

export const App_Setting_Mode = "testing"; // production | testing | localhost
const App_Version_Date = "05.25.2024 08p"; // day.mo0nth.yaer Hour Am/PM
// cbmis_website_productionBuild_05.25.2024 08p
// cbmis_website_testingBuild_05.25.2024 08p
// cbmis_website_clientCode_05.25.2024 08p
//====================================================================================================================================

export const App_Version = (App_Setting_Mode === "production" ? "P" : "T") + App_Version_Date;

export const ITC_System_ID = 3582916835;
export const Cat_Root_ID = 0;

export var App_IsInTestingMode = false;

export var App_Server_Url = "https://www.cbmisdb.link/";
export var App_Server_Url_DownloadFiles = "https://tms-cbmis-production.s3.eu-central-1.amazonaws.com/";

export const App_LocalStorage_Name = "aaoitSystemLocalStorageState";
export var App_LocalStorage_Encrypted_KEY = "2023";
export var App_LocalStorage_Is_Encrypted = true;

//#region hooks time
export var App_Login_HoursToCheck = 5 / 60;
export var System_Info_HoursToCheck = 5 / 60;
export var System_Info_Count_HoursToCheck = 5 / 60;

export var Cat_Info_HoursToCheck = 2;
//#endregion
if (App_Setting_Mode.toLowerCase() === "testing") {
  App_Server_Url = "https://www.cbmistesting.link/"; //"https://node.louayserver.click/" // "https://www.cbmistesting.link/"
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  System_Info_HoursToCheck = 5 / 60;
  App_Login_HoursToCheck = 5 / 60;
  Cat_Info_HoursToCheck = 2;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
} else if (App_Setting_Mode.toLowerCase() === "localhost") {
  App_Server_Url = "http://localhost:4000/";
  App_IsInTestingMode = true;
  App_LocalStorage_Is_Encrypted = false;
  System_Info_HoursToCheck = 0;
  Cat_Info_HoursToCheck = 0;
  App_Login_HoursToCheck = 0;
  App_Server_Url_DownloadFiles = "https://test-server-app.s3.eu-central-1.amazonaws.com/";
}

export var App_Server_Url_GraphQL_ITC = App_Server_Url + "itcapp/graphql";
export var App_Server_Url_UploadFiles_ITC = App_Server_Url + "itcapp/upload/s3";
export var App_Server_Url_SendEmail_ITC = App_Server_Url + "itcapp/sendemail";

export const Role_Public = 3553430577;
export const objUserRole = {
  Owner: 1764,
  Admin: 6517,
  Student: 7457,
};

export const objUserStatus = {
  Approved: 2085578067,
  Rejected: 8715548754,
  Applicant: 2535501102,
};

export const objUploadType = {
  aaiot: { fileType: "aaiot", id: "aaiot", sizeMb: 30, accept: "FILE" },
  announcement: { id: "announcement", fileType: "itc-announcement", sizeMb: 30, accept: "FILE" },

  profile: "profile",
};

export var App_Download_URL = "https://static-media-project.s3.eu-central-1.amazonaws.com/private-office-kuwait/";

export const counter = {
  1: "الأول",
  2: "الثاني",
  3: "الثالث",
  4: "الرابع",
  5: "الخامس",
  6: "السادس",
  7: "السابع",
  8: "الثامن",
  9: "التاسع",
  10: "العاشر",
};
