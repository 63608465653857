import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Grid, Line, Nav, Typography } from "@cbmisorg/client-app";
import { dictionary } from "../../../../../../appHelper/appLanguage/dictionary";

const classes = {
  footer: {
    background: `#24303E !important`,
    color: "#fff !important",
  },
  companyName: {
    borderBottom: "2px solid #fff",
    paddingBottom: "5px",
  },
  copyright: {
    background: "#141C23",
    color: "#fff",
  },
};

function Footer({ lang }) {
  const labels = dictionary?.components?.public?.home?.header;
  return (
    <React.Fragment>
      <Grid container my-0 py-0 sx={classes.footer} spacing="4" id="footer">
        <Grid item xs="12" md="3" container alignSelf="flex-start">
          <Grid item xs="12">
            <Typography as="subtitle1">
              {labels?.websiteName?.[lang]}
              <Line />
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Typography as="body2" xs="12">
              <p>00 West Broadway</p>
              <p>San Diego CA, 92101</p>
              <p>United States of America</p>
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Nav path="#contactus" p-0 className="contactNav">
              <Typography as="body1" p-0>
                {labels?.contactus?.[lang]}
              </Typography>
            </Nav>
          </Grid>
        </Grid>
        <Grid item xs="0" md="3" container alignSelf="flex-start">
          <Grid item xs="12">
            <Typography as="subtitle1" color="primary">
              {labels?.home?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Nav path="#whyChoose" p-0>
              <Typography as="body2">{labels?.whyChoose?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#lifecycle" p-0>
              <Typography as="body2">{labels?.lifecycle?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#vision" p-0>
              <Typography as="body2">{labels?.vision?.[lang]}</Typography>
            </Nav>
          </Grid>
        </Grid>
        <Grid item xs="0" md="3" container alignSelf="flex-start">
          <Grid item xs="12">
            <Nav path="#services" p-0>
              <Typography as="subtitle1" color="primary">
                {labels?.services?.[lang]}
              </Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#services" p-0>
              <Typography as="body2">{labels?.softwareDevelopment?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#services" p-0>
              <Typography as="body2">{labels?.consultationServices?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#services" p-0>
              <Typography as="body2">{labels?.projectManagement?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#services" p-0>
              <Typography as="body2">{labels?.training?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#services" p-0>
              <Typography as="body2">{labels?.cyberSecurity?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#services" p-0>
              <Typography as="body2">{labels?.educationalServices?.[lang]}</Typography>
            </Nav>
          </Grid>
        </Grid>
        <Grid item xs="0" md="3" container alignSelf="flex-start">
          <Grid item xs="12">
            <Typography as="subtitle1" color="primary">
              {labels?.support?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Nav path="#support" p-0>
              <Typography as="body2">{labels?.retail?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#support" p-0>
              <Typography as="body2">{labels?.travel?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#support" p-0>
              <Typography as="body2">{labels?.manufacturing?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#support" p-0>
              <Typography as="body2">{labels?.financial?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#support" p-0>
              <Typography as="body2">{labels?.emereging?.[lang]}</Typography>
            </Nav>
          </Grid>
          <Grid item xs="12">
            <Nav path="#support" p-0>
              <Typography as="body2">{labels?.education?.[lang]}</Typography>
            </Nav>
          </Grid>
        </Grid>

        <Grid item xs="12" md="0" container alignSelf="flex-start" pt-0>
          <Accordion dir={lang === "arb" ? "rtl" : "ltr"}>
            <AccordionTab>
              <AccordionSummary py-2>
                <Typography as="subtitle2" color="primary">
                  {labels?.home?.[lang]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container py-0>
                  <Grid item xs="12">
                    <Nav path="#whyChoose" p-0>
                      <Typography as="body2">{labels?.whyChoose?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#lifecycle" p-0>
                      <Typography as="body2">{labels?.lifecycle?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#vision" p-0>
                      <Typography as="body2">{labels?.vision?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </AccordionTab>
            <AccordionTab>
              <AccordionSummary py-2>
                <Typography as="subtitle2" color="primary">
                  {labels?.services?.[lang]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container py-0>
                  <Grid item xs="12">
                    <Nav path="#services" p-0>
                      <Typography as="body2">{labels?.softwareDevelopment?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#services" p-0>
                      <Typography as="body2">{labels?.consultationServices?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#services" p-0>
                      <Typography as="body2">{labels?.projectManagement?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#services" p-0>
                      <Typography as="body2">{labels?.training?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#services" p-0>
                      <Typography as="body2">{labels?.cyberSecurity?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#services" p-0>
                      <Typography as="body2">{labels?.educationalServices?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </AccordionTab>
            <AccordionTab>
              <AccordionSummary py-2>
                <Typography as="subtitle2" color="primary">
                  {labels?.support?.[lang]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container py-0>
                  <Grid item xs="12">
                    <Nav path="#support" p-0>
                      <Typography as="body2">{labels?.retail?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#support" p-0>
                      <Typography as="body2">{labels?.travel?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#support" p-0>
                      <Typography as="body2">{labels?.manufacturing?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#support" p-0>
                      <Typography as="body2">{labels?.financial?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#support" p-0>
                      <Typography as="body2">{labels?.emereging?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                  <Grid item xs="12">
                    <Nav path="#support" p-0>
                      <Typography as="body2">{labels?.education?.[lang]}</Typography>
                    </Nav>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </AccordionTab>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container justify="center" my-0 py-0 sx={classes.copyright}>
        <Grid item xs="12">
          <Typography as="body2">{labels?.copyRight?.[lang]}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Footer;
