const objAppRouting = {
  Public_LandingPage: { url: "/", featureID: 8786507229 },
  Public_Initiative: { url: "/initiative", featureID: 8786507229 },

  Public_Settings: { url: "/settings", featureID: 0 },

  Public_Login: { url: "/login", featureID: 2580267594 },
  Public_RestPassword: { url: "/restpassword", featureID: 0 },

  Account: { url: "/account", featureID: 6759118668, featureAuth: false },
  profile: { url: "/profile", featureID: 3195608470, featureAuth: false },

  Contact: { url: "/contact", featureID: 8465294220, featureAuth: false },
  viewEmail: { url: "/contact/viewemail", featureID: 8465294220, featureAuth: false },

  Manage_RequestJoin: { url: "/mngrequestjoin", featureID: 7004479076, featureAuth: false }, //manage trainee  

  Join_Trainee: { url: "/student", featureID: 5094561645 },
  Join_Startup: { url: "/startup", featureID: 5094561645 },
  Join_Manager: { url: "/managers", featureID: 1453065237 },

  MngAnnouncement: { url: "/mngannouncement", featureID: 1870026289, featureAuth: false },
  MngMeeting: { url: "/mngmeeting", featureID: 1870026289, featureAuth: false },
};

export default objAppRouting;
