import React from "react";
import { AppBar, GroupBar, Button, useNavigate, Nav, ClientDirection, Confirmation } from "@cbmisorg/client-app";
import logo from "../../../../../../assets/landing-images/logo.svg";
import { dictionary } from "../../../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../../../appHelper/routing/appRouting";
import { clearAppLocalStorage } from "../../../../../../appHelper/appFunctions";

function Header({ appState, appDispatch, navList, intRefreshCounter }) {
  const lang = appState?.clientInfo?.strLanguage;
  const navigate = useNavigate();

  const handelChangeLang = () => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
    appState.clientInfo.strLanguage = lang === "arb" ? "eng" : "arb";
    appState.clientInfo.strDataLanguage = lang === "arb" ? "eng" : "arb";
    appDispatch();
  };

  return (
    <React.Fragment>
      <AppBar sideBarClose={false} sideBarPosition={lang === "arb" ? "right" : "left"} key={String(intRefreshCounter)}>
        <GroupBar sxMode="navbar" ssMode="navbar">
          <img src={logo} alt="logo" height={"60px"} />
        </GroupBar>
        <GroupBar sxMode="navbar" sx={{ justifySelf: "flex-end !important" }}>
          <GroupBar sxMode="navbar" ssMode="sidebar">
            {navList[0]}
            {!appState?.clientInfo?.blnIsUserLoggedIn ? (
              <Button
                label={dictionary?.components?.public?.login?.heading?.[lang]}
                size="sm"
                mode="outlined"
                onClick={() => navigate(objAppRouting?.Public_Login?.url)}
                sx={{ sm: { marginTop: "5px !important" } }}
              />
            ) : (
              <Button
                label={dictionary?.components?.public?.footer?.account?.[lang]}
                size="sm"
                mode="outlined"
                onClick={() => navigate(objAppRouting?.Account?.url)}
                sx={{ sm: { marginTop: "5px !important" } }}
              />
            )}
            <Button icon="translate" size="sm" mode="outlined" onClick={handelChangeLang} className="iconBtn" sx={{ sm: { marginTop: "5px !important" } }} />
          </GroupBar>
        </GroupBar>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;

// <GroupBar>
// <GroupBar sxMode="navbar" ssMode="sidebar">{navList[0]}</GroupBar>
// <Grid item>
// {/*JXSButton*/}
// </Grid>
// </GroupBar>
// <GroupBar sxMode="none" ssMode="sidebar">
// {navList[0]}
// </GroupBar>
