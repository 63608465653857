import React, { useContext, useState } from 'react'
import { BtnGoToTop, ClientDirection, Loader, Nav, Typography } from '@cbmisorg/client-app'
import "./Home.css"


import Header from './sections/Header'
import Hero from './sections/Hero'
import Lifecycle from './sections/Lifecycle'
import Vision from './sections/Vision'
import Services from './sections/Services'
import Support from './sections/Support'
import ContactUs from './sections/ContactUs'
import Footer from './sections/Footer'
import ThingsYouGet from './sections/ThingsYouGet'
import WhyChoose from "./sections/WhyChoose";

import ParallaxScrollingApp from '../../sharedUi/ParallaxScrollingApp'
import { dictionary } from '../../../../../appHelper/appLanguage/dictionary'
import { AppContext } from '../../../../../configuration/contextapi/AppContext'
import { useEffect } from 'react'

function Home() {
  const {appState,appDispatch} = useContext(AppContext)
  const lang = appState?.clientInfo?.strLanguage
  const labels = dictionary?.components?.public?.home?.header

  
  const [stateInfo, setStateInfo] = useState({intRefreshCounter: 0})

  const closeDrawer = (sectionID ="") => {
    ++stateInfo.intRefreshCounter;
    setStateInfo({ ...stateInfo , sectionID:sectionID});
  };

  useEffect(() => {
    ClientDirection.setDirection(lang==="arb"?"rtl":"ltr")
  }, [lang])
  

  return (
    <section className='cbmis_landing_page'>
    <Header
    appState ={appState} 
    appDispatch ={appDispatch}
    intRefreshCounter={stateInfo.intRefreshCounter}
    sectionID={stateInfo?.sectionID}
    navList={[
      <React.Fragment>
        <Nav label={<Typography>{labels.home?.[lang]}</Typography>}
          menu={[
            <Nav label={<Typography color={"light"}>{labels?.whyChoose?.[lang]}</Typography>} path="#whyChoose"  onClick={()=>closeDrawer("#whyChoose")} />,
            <Nav label={<Typography color={"light"}>{labels?.lifecycle?.[lang]}</Typography>} path="#lifecycle"  onClick={()=>closeDrawer("#lifecycle")}/>,
            <Nav label={<Typography color={"light"}>{labels?.vision?.[lang]}</Typography>} path="#vision"  onClick={()=>closeDrawer("#vision")}/>,
          ]}
        />
        <Nav label={<Typography>{labels.services?.[lang]}</Typography>} path="#services"  onClick={()=>closeDrawer("#services")} />
        <Nav label={<Typography>{labels.support?.[lang]}</Typography>} path="#support"  onClick={()=>closeDrawer("#support")} />
        <Nav label={<Typography>{labels.thingsYouGet?.[lang]}</Typography>} path="#thingsYouGet"  onClick={()=>closeDrawer("#thingsYouGet")} />
        <Nav label={<Typography>{labels.contactus?.[lang]}</Typography>} path="#contactus"  onClick={()=>closeDrawer("#contactus")} />
      </React.Fragment>
    ]}
    />
    <Hero blnVideo={true}/>
    <React.Suspense fallback={<Loader color="primary" backdrop={true}  sx={{position:"absolute !important", bottom:"10px !important"}}/>}>
      <WhyChoose lang={lang} />
      <ParallaxScrollingApp imgNum={1}/>
      <Lifecycle lang={lang}/>
      <ParallaxScrollingApp imgNum={2} />
      <Vision lang={lang} />
      <ParallaxScrollingApp imgNum={3}/>
      <Services lang={lang} />
      <ParallaxScrollingApp imgNum={4}/>
      <Support lang={lang} />
      <ParallaxScrollingApp imgNum={5}/>
      <ThingsYouGet lang={lang} />
      <ParallaxScrollingApp imgNum={6}/>
      <ContactUs lang={lang} />
      <Footer lang={lang} />
      <BtnGoToTop className={lang==="arb"?"arbLang":""} icon={'KeyboardArrowUp'} />
      </React.Suspense>
    </section>
  )
}

export default Home