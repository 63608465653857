import { Alert, ClientFunction } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { fetchSendEmailPost } from "../../../../appHelper/appData/appFetch";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblUser = generateQueries("tblUser");

export const CtrlForgetPassword = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;
  // const jsnLoginConfig = objSystemInfo?.jsnLoginConfig;
  return {
    getEmail:
      ({ isLoading, setIsLoading, state, setState, fields }) =>
      async () => {
        if (!fields.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
          return;
        }
        setIsLoading(true);
        const result = await tblUser(strPageInfo, appState, "itcAppFindOne", {
          arrAttributes: ["bigUserID"],
          objectCondition: {
            strUserEmail: fields?.strUserEmail?.getValue(),
          },
        });

        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.email_Not_Used?.[lang], "warning");
          return;
        }
        state.strVerificationCode = ClientFunction.generateID(6);
        const emailTemplateParams = {
          emailTemplate: "forgetpassword",
          to: String(fields.strUserEmail.getValue()).toLowerCase().trim(),
          subject: "Email Verification",
          html: `Please use this verification code :\n\n${state.strVerificationCode}`,
        };

        let resultVerificationCode = await fetchSendEmailPost(strPageInfo, appState, emailTemplateParams);

        if (!resultVerificationCode?.blnIsRequestSuccessful || resultVerificationCode?.result !== 200) {
          Alert.viewAlert(dictionary?.shared.alertFetchNote?.emailCantSend?.[lang], "error");
          return false;
        }

        setIsLoading(false);
        state.bigUserID = result.response?.bigUserID;
        state.getEmail = fields.strUserEmail?.getValue();
        state.blnIsSendVerifyCode = true;
        setState({ ...state });
        fields.resetField();
      },
    getCode:
      ({ state, setState, fields }) =>
      () => {
        if (String(state.strVerificationCode) === fields?.strVerificationCode?.getValue()) {
          state.blnIsVerificationCode = true;
          setState({ ...state });
        }
      },
    funRestPassword:
      ({ isLoading, setIsLoading, state,fields , navigate}) =>
      async () => {
        try {
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          const result = await tblUser(strPageInfo, appState, "itcAppUserUpdate", {
            objectInput: {
              strUserPassword: fields?.strRestPassword?.getValue(),
              dtmUpdatedDate: new Date().toUTCString(),
            },
            objectCondition: {
              bigUserID: state?.bigUserID,
              blnIsActive: true,
              blnIsDeleted: false,
            },
          });

          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Update_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          navigate(objAppRouting?.Public_Login?.url)
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success");
          fields?.resetField();
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "getAllSystem-ERROR", error);
          setIsLoading(false);
        }
      },
  };
};
