import React, { useContext, useState } from "react";
import { AppContext } from "../../../../configuration/contextapi/AppContext";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { Accordion, AccordionDetails, AccordionSummary, AccordionTab, Avatar, Button, Container, Grid, Icon, useNavigate, Paper, Typography } from "@cbmisorg/client-app";
import AnnouncementDisplay from "../../admin/mngAnnouncement/mngAnnouncement.display";
import { App_Dark_blue, App_Primary_Color } from "../../../../appHelper/appColor";
import RouteProfile from "../../shared/profile/RouteProfile";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";

//#region style
const classes = {
  readMore: {
    overflow: "hidden !important",
    whiteSpace: "nowrap !important",
    wordWrap: "break-word !important",
    lineHeight: "1.5 !important",
    lineClamp: "5 !important",
    height: `calc(15px * 1.5 * 1)`,
    maxHeight: `calc(15px * 1.5 * 5)`,
    transition: "max-height .5s ease !important",
    textOverflow: "ellipsis !important",
  },
  announcementPaper: {
    boxShadow: "none !important",
    borderRadius: "calc(40 * 1px) !important",
    backgroundColor: "#ffffff55 !important",
  },
  card: {
    position: "relative !important",
    display: "block !important",
    height: "100% !important",
    borderRadius: "calc(40 * 1px) !important",
    overflow: "hidden !important",
    textDecoration: "none !important",
    boxShadow: "none !important",
    padding: "0 !important",
    cursor: "pointer !important",
    backgroundColor: "#DFF5FF !important",
  },
  cardImage: {
    width: "100%",
    minHeight: "250px",
    height: "auto",
    maxHeight: "250px",
  },
  cardOverlay: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "1",
    borderRadius: "calc(40 * 1px)",
    backgroundColor: "#fff",
    transform: "translateY(100%)",
    transition: ".2s ease-in-out",
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "1em",
    borderRadius: "calc(40 * 1px) 0 0 0",
    backgroundColor: "#fff",
    transform: "translateY(-100%)",
    transition: ".2s ease-in-out",
  },
  cardArc: {
    width: "80px",
    height: "80px",
    position: "absolute",
    bottom: "100%",
    right: "0",
    zIndex: 1,
  },
  cardTitle: {
    margin: "0 0 .3em",
    color: "#6A515E",
  },
  cardDescription: {
    padding: "0 2em 2em",
    margin: 0,
    color: App_Dark_blue,
    fontFamily: "MockFlowFont",
    display: " -webkit-box",
    webkitBoxOrient: "vertical",
    webkitLineClamp: 3,
    overflow: "hidden",
  },
};
//#endregion

const labels = dictionary?.shared?.accountSection;
function AccountSponsor() {
  useCheckSystemInfo();
  const { appState } = useContext(AppContext);
  const navigate = useNavigate();
  const lang = appState?.clientInfo?.strLanguage;
  const [state, setState] = useState({
    objConfig: {
      blnNewServices: false,
      blnNewTraining: true,
    },
    lstServices: [
      {
        title: { eng: "Profile", arb: "المعلومات الشخصية" },
        dec: {
          eng: "This platform allows you to hire professional Kuwaiti programmers specialized in building a secure application that operates on any device or operating system",
          arb: "تتيح هذه المنصة إمكانية توظيف مجموعة من المبرمجين المحترفين الكويتيين و المتخصصين في بناء تطبيق واحد آمن يعمل على أي جهاز او نظام تشغيل",
        },
        icon: "accountCircle",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/user-profile-3465537-2928727.png?f=webp",
        url: objAppRouting?.Account?.url,
      },
      {
        title: { eng: "Developers Hub", arb: "مركز المبرمجين" },
        dec: {
          eng: "This platform allows you to hire professional Kuwaiti programmers specialized in building a secure application that operates on any device or operating system",
          arb: "تتيح هذه المنصة إمكانية توظيف مجموعة من المبرمجين المحترفين الكويتيين و المتخصصين في بناء تطبيق واحد آمن يعمل على أي جهاز او نظام تشغيل",
        },
        icon: "ArticleOutlined",
        img: "https://www.lauruss.com/wp-content/uploads/2022/02/Top-ASP.NET-Development-Company.svg",
      },
      {
        title: { eng: "Job Portals", arb: "منصة الوظائف" },
        dec: {
          eng: "This job advertising platform enables you to manage all job postings under your company's name and gives you access to a wide range of qualified employees, facilitating easy selection according to the requirements of each position",
          arb: "تمكنك منصة الإعلان عن وظائف من إدارة جميع الوظائف المطروحة تحت اسم منشئتك كما وتمكنك من الوصول الى شريحة واسعة من الموظفين المؤهلين وسهولة إختيار الأنسب على حسب متطلبات كل وظيفة",
        },
        icon: "workOutline",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/employee-searching-job-openings-2726133-2272618.png?f=webp",
      },
      {
        title: { eng: "Company as a Service", arb: "خدمات الشركات" },
        dec: {
          eng: "This platform provides comprehensive services for software companies, enabling them to efficiently and effectively manage their employees and technical projects, while ensuring the completion of their applications with minimal cost and effort",
          arb: "هذه المنصة توفر خدمات متكاملة لشركات البرمجة، تمكّنها من إدارة موظفيها ومشاريعها التقنية بكفاءة وفعالية عالية، مع ضمان إنجاز تطبيقاتها بأقل تكلفة وجهد",
        },
        icon: "BadgeOutlined",
        img: "https://doflex.io/wp-content/uploads/2022/10/booking-re%CC%81union-Modifie%CC%81.png",
      },
      {
        title: { eng: "Development Tools", arb: "أدوات تطوير التطبيقات" },
        dec: {
          eng: "A set of software tools and packages to help developers and programming companies design secure enterprise applications in less time and effort",
          arb: "مجموعة من الأدوات البرمجية لمساعدة المطورين وشركات البرمجة في تصميم تطبيقات آمنة بأقل وقت وجهد ",
        },
        icon: "TapAndPlay",
        img: "https://cdni.iconscout.com/illustration/premium/thumb/web-development-3454628-2918517.png",
      },
    ],

    lstNotification: [],
    sectionSelectedKey: "account",
  });

  const { objAnnouncement } = AnnouncementDisplay();

  return (
    <React.Fragment>
      {state?.sectionSelectedKey === "account" ? (
        <Container my-auto>
          <Grid container spacing={3}>
            <Grid item xs="12">
              <Paper sx={classes?.announcementPaper} mx-2 p-0>
                <Grid container>
                  <Grid item xs="0" sm="2">
                    <img src="https://cdni.iconscout.com/illustration/premium/thumb/announcement-3162051-2646148.png" alt="" width={"100%"} />
                  </Grid>
                  <Grid item xs="12" sm="10" container>
                    <Grid item xs="12" py-0>
                      <Typography as="subtitle2" color={App_Primary_Color} my-0 py-0></Typography>
                    </Grid>
                    <Grid item xs="12">
                      <Accordion dir={lang === "arb" ? "rtl" : "ltr"}>
                        {(objAnnouncement.lstNotification || [])?.map((msg, intIndex) => (
                          <AccordionTab sx={{ background: "transparent", boxShadow: "none !important" }} key={intIndex} px-0>
                            <AccordionSummary expandIcon={<Icon icon="campaign" color={App_Primary_Color} />} sx={{ borderColor: "#fefef", boxShadow: "none !important" }}>
                              <Typography as="subtitle2" sx={classes?.readMore}>
                                {msg?.strAnnouncementTitle?.[lang]}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails px-3>
                              <Grid container>
                                <Grid item xs="12">
                                  <Typography as="body2" color={App_Primary_Color}>
                                    {msg?.strAnnouncementTitle?.[lang]}
                                  </Typography>{" "}
                                </Grid>
                                <Grid item xs="12">
                                  <Typography as="caption">{msg?.strAnnouncementBody?.[lang]}</Typography>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </AccordionTab>
                        ))}
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <React.Fragment>
            <Grid container alignSelf="start" justifyContent="flex-start" spacing="2">
              <Grid item xs={"12"}>
                <Grid container justifyContent="flex-start" spacing={3}>
                  {state?.lstServices?.map((service, ind) => (
                    <Grid item xs={12} sm="6" md="4" lg="3" key={ind}>
                      <Paper sx={classes?.card} className="serv-card-account">
                        <img src={service.img} style={classes?.cardImage} alt="" />
                        <div style={classes?.cardOverlay} className="cardOverlay">
                          <div style={classes?.cardHeader} className="cardHeader">
                            <svg style={classes?.cardArc} className="cardArc">
                              <path />
                            </svg>
                            <Grid container>
                              <Grid item>
                                <Avatar className="servBgColor" sx={{ width: "35px", height: "35px" }}>
                                  <Icon icon={service?.icon} color="#fff" size="30px" />
                                </Avatar>
                              </Grid>
                              <Grid item xs="8" mt-1>
                                <Typography as="body2" sx={classes?.cardTitle}>
                                  {service?.title?.[lang]}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                          <Grid container>
                            <Grid item xs="12">
                              <Typography as="caption" sx={classes?.cardDescription}>
                                {service?.dec?.[lang]}
                              </Typography>
                            </Grid>
                            {!service?.url ? null : (
                              <Grid item sx={{position:"relative", top:"-15px", marginInlineStart:"25px"}}>
                                <Button className="servBgColor" icon="openInNew" onClick={() => navigate(objAppRouting?.profile?.url)} m-0 />
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        </Container>
      ) : (
        <RouteProfile header={false} />
      )}
    </React.Fragment>
  );
}

export default AccountSponsor;
