import React from "react";
import { ParallaxScrolling } from "@cbmisorg/client-app";
 
const classes = {
  parallaxScrolling: {
    minHeight: "65px !important",
    maxHeight: "65px !important",
    position: "relative",
    backgroundPositionX: " 50% !important",
  },
};

const img1 = require("../../../../assets/landing-images/dev1.jpg")
const img2 = require("../../../../assets/landing-images/dev2.jpg")
const img3 = require("../../../../assets/landing-images/dev3.jpg")
const img4 = require("../../../../assets/landing-images/dev4.jpg")
const img5 = require("../../../../assets/landing-images/dev5.jpg")
const img6 = require("../../../../assets/landing-images/dev6.jpg")


function ParallaxScrollingApp({imgNum}) {

  let image =img1
  switch (imgNum) {
    case 2:
      image = img2
      break;
    case 3:
      image = img3
      break;
    case 4:
      image = img4
      break;
    case 5:
      image = img5
      break;
    case 6:
        image = img6
        break;
    default:
      image = img1
      break;
  }

  return (
    <React.Fragment>
      <ParallaxScrolling src={image} sx={classes?.parallaxScrolling} loading="lazy"  /> 
    </React.Fragment>
  );
}

export default ParallaxScrollingApp;
