import { Alert, ClientFunction } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { ITC_System_ID, objUserRole } from "../../../../appHelper/appVariables";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblUser = generateQueries("tblUser");

export const CtrlJoinManager = (appState, appDispatch) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;
  // const objSystemInfo = appState?.clientInfo?.objSystemInfo;
  // const jsnLoginConfig = objSystemInfo?.jsnLoginConfig;

  return {
    initData:
      ({ isLoading, setIsLoading, state, setState, fields, checkHookValidity }) =>
      async () => {
        try {
          if (isLoading) {
            //Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
            return;
          }

          setIsLoading(true);
          state.blnIsInitDone = true;

          const catFromHook = await checkHookValidity();
          const objCat = catFromHook || appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

          let lstDegreeOption = [];
          let objDegreeOptionLang = {};

          let lstMajorOption = [];
          let objMajorOptionLang = {};

          let lstGenderOption = [];
          let objGenderOptionLang = {};

          Object.entries(objCat?.Root?.degree || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstDegreeOption.push({ key: value?.id, value: value?.id });
              objDegreeOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.id });
              objMajorOptionLang[value?.id] = value?.value;
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.id });
              objGenderOptionLang[value?.id] = value?.value;
            }
          });

          fields.bigMajorID.setOptions(lstMajorOption, objMajorOptionLang);
          fields.bigDegreeID.setOptions(lstDegreeOption, objDegreeOptionLang);
          fields.bigGender.setOptions(lstGenderOption, objGenderOptionLang);

          setIsLoading(false);
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          appFunctions.logMessage(strPageInfo, error, "routeMngRequestedJoinCtrl-Error");
        }
      },

    addJoinTraining:
      ({ fields, isLoading, setIsLoading, navigate, location }) =>
      async () => {
        try {
          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "");
            return;
          }
          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "");
            return;
          }
          setIsLoading(true);

          let bigID = Number(ClientFunction.generateID(10));

          const result = await tblUser(strPageInfo, appState, "itcAppUserAddCustom", {
            objectInput: {
              bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || ITC_System_ID,
              bigUserID: bigID,

              strUserEmail: fields?.strUserEmail?.getValue(),
              strUserPhone: fields?.strUserPhone?.getValue(), //NEED GLOBAL PHONE KEY  (00)
              strUserPassword: fields?.strUserPassword?.getValue(),

              jsnUserInfo: {
                strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },                
                strMajor: fields?.bigMajorID?.getValue()?.key,
                bigDegreeID: fields?.bigDegreeID?.getValue()?.key,
                bigGender: fields?.bigGender?.getValue()?.key,
                strWorkPlace: fields.strWorkGovPlace?.getValue(),
                strPositionTitle: fields?.strPositionTitle?.getValue(),

                intUserRoleFK: objUserRole?.Student,
              },
              blnIsManager: true,

              strStatisticFelid: "intCountJoinManager",
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning");
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          fields.resetField();
          appState.clientInfo.blnIsUserLoggedIn = true;
          appState.userInfo = result.response;
          appDispatch();
          navigate(objAppRouting?.Account?.url, { replace: true });
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "addJoinTraining-ERROR", error);
          setIsLoading(false);
        }
      },
    getPhoneGlobalKey: (bigCountryID) => {
      try {
        const objCat = appFunctions.getCategoryCache();
        return appFunctions.appCategoryGetValue(objCat?.Root?.country, bigCountryID, "phoneCode", true);
      } catch {
        return "";
      }
    },
  };
};
