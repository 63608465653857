import { Avatar, Container, Grid, Icon, Typography } from '@cbmisorg/client-app'
import React from 'react'
import { dictionary } from '../../../../../../appHelper/appLanguage/dictionary';
import { App_Red_Color } from '../../../../../../appHelper/appColor';

const lstIcon = ["work","IntegrationInstructions","Inventory2","Assessment","FilterFrames","ShoppingCart"];

function ThingsYouGet({lang}) {
    const labels = dictionary?.components?.public?.home?.thingsYouGet

    return (
        <React.Fragment>
            <Container py-10 id="thingsYouGet">
                <Grid container spacing={0}  justify={'center'}>
                    <Grid item xs="12">
                        <Typography as="h4" >
                            {labels?.title?.[lang]}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={5} justify={'center'} py-5>
                {
                    labels?.lst?.map((item,index)=>{
                        return(
                            <Grid item xs="12" md="6" lg="4" container key={index} justify={'center'} alignSelf={'flex-start'} spacing={0}>
                                <Grid item xs="12" py-3>
                                    <Avatar color={App_Red_Color} sx={{width:"45px !important", height:"45px !important"}}>
                                        <Icon icon={lstIcon?.[index]} color={"#fff"}/>
                                    </Avatar>
                                </Grid>
                                <Grid item xs="12">
                                    <Typography as="h6">
                                        {item?.heading?.[lang]}
                                    </Typography>
                                </Grid>
                                <Grid item xs="12">
                                    <Typography as="body1">
                                        {item?.describe?.[lang]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    })
                }
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default ThingsYouGet