//#region ******************************************************* //
/*
  *This is the Router called from App.js
*/
//#endregion ************************************************* //

import React from 'react'
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider} from "@cbmisorg/client-app";

import SecureRoute from '../appHelper/routing/SecureRoute';
import objAppRouting from '../appHelper/routing/appRouting';

import Account from '../components/stakeholders/shared/account/Account';
import RouteLogin from '../components/public/login/RouteLogin';
import { getAppLocalStorage } from '../appHelper/appFunctions';


import RouteContact from '../components/stakeholders/shared/contact/RouteContact';
import RouteViewContact from '../components/stakeholders/shared/contact/RouteViewContact';
import RouteMngRequestedJoin from '../components/stakeholders/shared/mngRequestedJoin/RouteMngRequestedJoin';


import RouteProfile from '../components/stakeholders/shared/profile/RouteProfile';
 
import JoinTrainee from '../components/public/joinTrainee/JoinTrainee';
import JoinManager from '../components/public/joinManager/JoinManager';

import MngAnnouncement from '../components/stakeholders/admin/mngAnnouncement';
import MngMeeting from '../components/stakeholders/admin/mngMeeting';


import Initiative from '../components/public/initiative';
import ForgetPassword from '../components/public/forgetPasswoed/ForgetPassword';
import RouteLandingPage from '../components/public/LandingPage/RouteLandingPage';

// const RouteLandingPage  =lazy(()=>import('../components/public/LandingPage/RouteLandingPage'))
const locationState = getAppLocalStorage()

function Router() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<React.Fragment />}>
        <Route path={objAppRouting.Public_LandingPage.url} element={<SecureRoute><RouteLandingPage /></SecureRoute>} />

        <Route path={objAppRouting.Public_Initiative.url} element={<SecureRoute><Initiative /></SecureRoute>} />          
 
 
        <Route path={objAppRouting.Join_Startup.url} element={<SecureRoute><JoinTrainee blnIsStartUp={true} /></SecureRoute>} />
        <Route path={objAppRouting.Join_Trainee.url} element={<SecureRoute><JoinTrainee blnIsStartUp={false} /></SecureRoute>} />
        <Route path={objAppRouting.Join_Manager.url} element={<SecureRoute><JoinManager /></SecureRoute>} />
   
        <Route path={objAppRouting.Public_Login.url} element={<SecureRoute><RouteLogin /></SecureRoute>} /> 
        <Route path={objAppRouting.Public_RestPassword.url} element={<SecureRoute><ForgetPassword /></SecureRoute>} /> 

        <Route path={objAppRouting.Account.url} element={<SecureRoute><Account /></SecureRoute>} />


        <Route path={objAppRouting.Contact.url} element={<SecureRoute><RouteContact /></SecureRoute>} />
        <Route path={objAppRouting.viewEmail.url} element={<SecureRoute><RouteViewContact /></SecureRoute>} />        

        <Route path={objAppRouting.Manage_RequestJoin.url} element={<SecureRoute><RouteMngRequestedJoin /></SecureRoute>} />                                        
                       
        <Route path={objAppRouting.profile.url} element={<SecureRoute><RouteProfile /></SecureRoute>} />                

        <Route path={objAppRouting.MngAnnouncement.url} element={<SecureRoute><MngAnnouncement /></SecureRoute>} />
        <Route path={objAppRouting.MngMeeting.url} element={<SecureRoute><MngMeeting /></SecureRoute>} />

      </Route>
    )
  )

  return <RouterProvider router={router} />;

}

export default Router




