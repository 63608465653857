import { Alert } from "@cbmisorg/client-app";
import * as appFunctions from "../../../../appHelper/appFunctions";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblUser = generateQueries("tblUser");

export const controller = (appState, appDispatch, isLoading, setIsLoading) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;
  const objSystemInfo = appState?.clientInfo?.objSystemInfo?.[appState?.clientInfo?.bigSystemID];
  const jsnLoginConfig = objSystemInfo?.jsnLoginConfig;

  return {
    submitLogin:
      ({ navigate, fields }) =>
      async () => {
        try {
          if (appState?.clientInfo?.blnIsUserLoggedIn) {
            navigate(objAppRouting.Account.url, { replace: true });
            return;
          }

          if (!fields.isFormValid()) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.invalid_Field?.[lang], "error");
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang]);
            return;
          }

          setIsLoading(true);

          const result = await tblUser(strPageInfo, appState, "itcAppUserLogin", {
            objectCondition: {
              strUserEmail:
                jsnLoginConfig?.blnCanLoginByEmail || (!jsnLoginConfig?.blnCanLoginByUsername && !jsnLoginConfig?.blnCanLoginByPhone)
                  ? fields?.strUserEmail?.getValue()
                  : undefined,
              strUsername: jsnLoginConfig?.blnCanLoginByUsername ? fields?.strUsername?.getValue() : undefined,
              strUserPhone: jsnLoginConfig?.blnCanLoginByPhone ? fields?.strUserPhone?.getValue() : undefined,
              strUserPassword: fields?.strUserPassword?.getValue(),
            },
          });

          if (!result.blnIsRequestSuccessful) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalidLoginInfo?.[lang], "warning");
            setIsLoading(false);
            return;
          }

          appState.clientInfo.blnIsUserLoggedIn = true;
          appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
          appState.userInfo = result.response;

          appDispatch();

          navigate(objAppRouting.Account.url, { replace: true });

          setIsLoading(false);
        } catch (error) {
          appFunctions.logMessage(strPageInfo, "submitLogin-ERROR", error);
          setIsLoading(false);
        }
      },
  };
};
