import React from 'react'
import { Container} from '@cbmisorg/client-app';
import poster from "../../../../../../assets/landing-images/cbmis.png" ;

// import vedio from "../../../../assets/cbm.mp4" ;
let mediaUrl = `https://cbmis.s3.eu-central-1.amazonaws.com/cbm.26caee39a4661211d6fd.mp4`
// `https://static-media-project.s3.eu-central-1.amazonaws.com/cbmisImg/cbm.26caee39a4661211d6fd.mp4`
const classes = {
  background: {
      width:"100% !important",
  },
};



function Hero({blnVideo}) {
  // window.videojs(refVideoEl.current)

  return (
    <React.Fragment>
     <Container sx={{...classes?.background}} m-0 p-0>
      <video autoPlay={false} muted={false} playsInline controls={true} preload='auto' className='heroVideo'  poster={poster} >
      {
        blnVideo && (<source src={mediaUrl} type='video/mp4' />)
      }
    </video>
   
     </Container>

    </React.Fragment>
  )
}

export default Hero