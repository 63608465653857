"use client";
import React from "react";
import { App_Setting_Mode } from "../../../appHelper/appVariables";

const proLink = "https://initiative.cbmis.com/web/";
const testLink = "https://initiative.cbmis.click/web/";
function Initiative() {
  const searchParams = new URL(window.location.href);
  const type = searchParams.searchParams.get("type") || "pwa";
  const url = searchParams.searchParams.get("url");

  return <iframe src={`${App_Setting_Mode === "production" ? proLink : testLink}${type}/${url}/`} style={{ width: "100vw", height: "99vh" }}></iframe>;
}

export default Initiative;
