import { AppBar, ClientDirection, GroupBar, Nav, Typography } from "@cbmisorg/client-app";
import React, { useEffect } from "react";
import objAppRouting from "../../../appHelper/routing/appRouting";
import { dictionary } from "../../../appHelper/appLanguage/dictionary";
import { App_Primary_Color } from "../../../appHelper/appColor";
import { getNextStrURL } from "../../../appHelper/appFunctions";

function ShredHeader({ title, lang = "arb", blnBottomBar = true, blnBack = true, blnHome = true, blnLogout = false, funLogout, blnLang, changLang, blnNavbar=true }) {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  return (
    <AppBar dir="ltr" sx={blnNavbar?{}:{display:"none"}}>
      {!blnNavbar ? <GroupBar sxMode={"none"} ssMode={"none"} /> : (
        <React.Fragment>
          <GroupBar sxMode={"navbar"} ssMode={"navbar"} sx={{ color: App_Primary_Color }}>
            {blnBack ? <Nav icon="arrowBack" path={"-1"} /> : null}
            {!blnLang ? null : <Nav icon="translate" onClick={changLang} />}
          </GroupBar>
          <GroupBar sxMode={"navbar"} ssMode={"navbar"}>
            <Typography as="subtitle1" color="primary">
              {title}
            </Typography>
          </GroupBar>
          <GroupBar sxMode={"navbar"} ssMode={"navbar"} sx={{ color: App_Primary_Color }}>
            {!blnHome ? null : <Nav icon="home" path={objAppRouting?.Public_LandingPage?.url} />}
            {!blnLogout ? null : <Nav icon="logout" onClick={funLogout} />}

          </GroupBar>
        </React.Fragment>
      )}

      {
      //   blnBottomBar ? (
      //   <GroupBar sxMode="none" dir="rtl">
      //     <Nav ssMode="bottombar" icon="accountCircle" label={<>{dictionary?.components?.public?.footer?.account?.[lang]}</>} path={getNextStrURL()} />
      //     <Nav ssMode="bottombar" icon="home" label={<>{dictionary?.components?.public?.footer?.home?.[lang]}</>} path={objAppRouting.Public_LandingPage.url} />
      //     <Nav ssMode="bottombar" icon="settings" label={<>{dictionary?.components?.public?.footer?.settings?.[lang]}</>} path={objAppRouting.Public_Settings.url} />
      //   </GroupBar>
      // ) : (
      //   <GroupBar sxMode="none" dir="rtl" />
      // )
    }
    </AppBar>
  );
}

export default ShredHeader;
