import { Button, Container, Grid, Tabs, Typography } from "@cbmisorg/client-app";
import React from "react";
import { dictionary } from "../../../../../../appHelper/appLanguage/dictionary";
import { FormData, FormField } from "../../../../../../appHelper/formApi/FormAPI";
import { App_Primary_Color, App_Red_Color, App_Second_Color } from "../../../../../../appHelper/appColor";

const classes = {
  tab: {
    backgroundColor: "transparent",
    ".cbmis-tabs-head": {
      width: "300px !important",
      margin: "auto !important",
    },
  },
};
function ContactUs({ lang }) {
  const labels = dictionary?.components?.public?.home?.contactus;

  const developmentFields = FormData({
    strFullName: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    strUserPhoneNumber: {
      strControl: "text",
      objValidations: { required: true, onlyNumbers: true },
      objLanguage: false,
    },
    strUserEmail: {
      strControl: "text",
      objValidations: { required: true, email: true },
      objLanguage: false,
    },
    strSubject: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    strBestToCall: {
      strControl: "date",
      objValidations: { required: true },
      objLanguage: false,
    },
    strSoftwareNeededOn: {
      strControl: "date",
      objValidations: { required: true },
      objLanguage: false,
    },
    strProjectType: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    strMsg: {
      strControl: "textarea",
      objValidations: { required: true },
      objLanguage: false,
    },
  });

  const consultationFields = FormData({
    strCompanyName: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    strCompanyWebsite: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    strCompanyEmail: {
      strControl: "text",
      objValidations: { required: true, email: true },
      objLanguage: false,
    },
    strPhoneNumber: {
      strControl: "text",
      objValidations: { required: true, onlyNumbers: true },
      objLanguage: false,
    },
    strCompanyAddress: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    intCompanyEmployeeNum: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    strTypeConsultaionService: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    strBestToCall: {
      strControl: "date",
      objValidations: { required: true },
      objLanguage: false,
    },
    strProjectType: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    strBusinessIndustry: {
      strControl: "text",
      objValidations: { required: true },
      objLanguage: false,
    },
    strMsg: {
      strControl: "textarea",
      objValidations: { required: true },
      objLanguage: false,
    },
  });

  const handleSubmit = () => {};

  const tabsContent = [
    {
      head: (
        <Typography as="" color={App_Primary_Color}>
          {labels.consultation?.[lang]}
        </Typography>
      ),
      content: (
        <Grid container spacing={3} justify={"center"}>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.strCompanyName} icon="business" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.strCompanyWebsite} icon="public" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.strCompanyEmail} icon="email" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.strPhoneNumber} icon="phone" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.strCompanyAddress} icon="gpsFixed" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.intCompanyEmployeeNum} icon="people" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.strTypeConsultaionService} icon="work" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.strBestToCall} type="time"/>
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.strProjectType} icon="SettingsApplications" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={consultationFields?.strBusinessIndustry} icon="construction" />
          </Grid>
          <Grid item xs={"12"} py-2>
            <FormField objHandler={consultationFields.strMsg} />
          </Grid>
          <Grid item xs={"12"} py-2>
            <Button label={dictionary.shared?.buttons?.sendBtn?.[lang]} py-0 px-5 onClick={handleSubmit} />
          </Grid>
        </Grid>
      ),
    },
    {
      head: (
        <Typography as="" color={App_Primary_Color}>
          {labels.development?.[lang]}
        </Typography>
      ),
      content: (
        <Grid container spacing={3} justify={"center"}>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={developmentFields?.strFullName} icon="accountCircle" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={developmentFields?.strUserPhoneNumber} icon="phone" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={developmentFields?.strUserEmail} icon="email" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={developmentFields?.strProjectType} icon="SettingsApplications" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={developmentFields?.strBestToCall} type="time" icon="accessTime" />
          </Grid>
          <Grid item md={"6"} xs={"12"} py-2>
            <FormField objHandler={developmentFields?.strSoftwareNeededOn} icon="today" />
          </Grid>
          <Grid item xs={"12"} py-2>
            <FormField objHandler={developmentFields.strMsg} />
          </Grid>
          <Grid item xs={"12"} py-2>
            <Button label={dictionary.shared?.buttons?.sendBtn?.[lang]} py-0 px-5 onClick={handleSubmit} />
          </Grid>
        </Grid>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Container py-10 id="contactus">
        <Grid container spacing={1} justify={"center"}>
          <Grid item xs="12">
            <Typography as="h4" px-3>
              {labels?.title?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Tabs tabsContent={tabsContent} activeColor={App_Red_Color} sx={classes?.tab} />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default ContactUs;
